import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import featImageCollection from '@/constants/images/featImageCollection'
import {featCollection} from '@/constants/featList'

const FeatImageListContainer = ({ featId }) => {
  if (featId) {
    const feat = featCollection[featId]
    const imageList = featImageCollection[featId]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...feat}
        />
      )
    }

    return null
  }

  return null
}

FeatImageListContainer.propTypes = {
  featId: PropTypes.string.isRequired,
}

export default FeatImageListContainer
