import React from 'react'

import { featCollection } from '@/constants/featList'

import AbandonedAt from '@/components/AbandonedAt'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs'
import SourceInfo from '@/components/SourceInfo'

import generateFeatPageUrlById from '@/utils/generateFeatPageUrlById'
import generateTextLinks from '@/utils/generateTextLinks'

import FeatImageList from './components/FeatImageList'

import './FeatStyles.less'

const FeatComponent = (
  {
    abandonedAt,
    description,
    header,
    id,
    releasedAs,
    releasedBeforeAsList,
    subHeader,
    source,
    requirementText,
  },
) => (
  <section className='Feat'>
    <section className='Feat_infoColumn'>
      <section className='Feat_infoBlock'>
        <HeaderBlock
          className='Feat_header'
          subHeader={subHeader}
        >
          {header}
        </HeaderBlock>
        <AbandonedAt
          className='Feat_releaseInfo'
          preText='Эта черта была отменена'
          abandonedAt={abandonedAt}
        />

        <ReleasedAs
          className='Feat_releaseInfo'
          preText='Эта черта была переиздана'
          releasedAs={releasedAs}
          collection={featCollection}
          urlGenerator={generateFeatPageUrlById}
        />
        {
          requirementText
            ? (
              <div className='Feat_requirement'>
                Требования: {requirementText}
              </div>
            )
            : null
        }
        <Markdown className='Feat_description'>
          {generateTextLinks(description)}
        </Markdown>
        <SourceInfo
          className='Feat_source'
          source={source}
          useFullName
        />
      </section>

      <ReleasedBeforeAs
        preText='Эта черта ранее издавалась'
        releasedAs={releasedBeforeAsList}
        checkIsReady={false}
        collection={featCollection}
        urlGenerator={generateFeatPageUrlById}
      />
    </section>

    <FeatImageList featId={id}/>
  </section>
)

export default FeatComponent
