const arrify = require('arrify')

const generateListReleasedBeforeAs = require('./../../utils/generateListReleasedBeforeAs')
const prepareForSearch = require('./../../utils/prepareForSearch')
const listToCollectionById = require('./../../utils/listToCollectionById')
const sortByName = require('./../../utils/sortByName')
const checkIsFromUA = require('./../../utils/checkIsFromUA')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const pcRaceIdList = require('./../pcRaceIdList')
const pcSubraceIdList = require('./../pcSubraceIdList')

const featRawList = require('./featRawList')

const extractPropList = (propName, data) => {
  const requirementList = arrify(data)
    .filter(
      item => item[propName],
    )
    .reduce(
      (list, item) => {
        if (!item[propName]) {
          return list
        }

        return [
          ...list,
          ...arrify(item[propName])
        ]
      },
      [],
    )

  if (requirementList.length) {
    return requirementList
  }

  return propName === 'race'
    ? pcRaceIdList
    : pcSubraceIdList
}

const featList = featRawList
  .map(
    (item, i, list) => ({
      ...item,
      releasedBeforeAsList: generateListReleasedBeforeAs(list, item.id),
      requirementRaceIdList: item.requirement
        ? extractPropList('race', item.requirement)
        : null,
      requirementLvl: item.requirement && item.requirement.lvl || 1,
      requirementSubraceIdList: item.requirement
        ? extractPropList('subrace', item.requirement)
        : null,
      isUA: checkIsFromUA(item.source),
      isReady: 'isReady' in item ? item.isReady : !item.isNotReady,
      isReReleased: Boolean(item.releasedAs),
      isAbandoned: Boolean(item.abandonedAt),
      releasedAs: item.releasedAs || null,
      [SEARCH_PROP_NAME]: prepareForSearch(
        [
          item.name,
          item.nameAlt || '',
          item.nameEn,
          item.description,
        ]
          .filter(e => e)
          .map(
            e => String(e).toLowerCase(),
          )
          .join('\n'),
      ),
    }),
  )
  .sort(sortByName)

module.exports = featList

module.exports.featCollection = listToCollectionById(featList)
