import arrify from 'arrify'

import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import pcRaceList from '@/constants/pcRaceList'
import pcSubraceList, {pcSubraceCollection} from '@/constants/pcSubraceList'
import featList from '@/constants/featList'
import {paramFullList} from '@/constants/paramList'
import skillList, {skillCollection} from '@/constants/skillList'
import instrumentList, {instrumentCollection} from '@/constants/instrumentList'
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

import upLetter from '@/utils/upLetter'
import sortByText from '@/utils/sortByText'

const raceListFiltered = pcRaceList.filter(
  ({ id: raceId }) => featList.find(
    ({requirement}) => requirement && requirement.race === raceId
  )
)

const subraceListFiltered = pcSubraceList.filter(
  ({ id: subraceId }) => featList.find(
    ({requirement}) => requirement && requirement.subrace === subraceId
  )
)

const skillListFiltered = skillList
  .filter(
    ({ id: skillId }) => featList.find(
      ({skillList: list}) => list && arrify(list).includes(skillId)
    )
  )
  .map(
    ({ id }) => id
  )

const instrumentListFiltered = instrumentList
  .filter(
    ({ id: instrumentId }) => featList.find(
      ({instrumentList: list}) => list && arrify(list).includes(instrumentId)
    )
  )
  .map(
    ({ id }) => id
  )

const racePropName = 'requirementRaceIdList'
const subRacePropName = 'requirementSubraceIdList'

export default [
  {
    header: 'Требования',
    children: [
      {
        label: 'Раса',
        type: FILTER_TYPE_SELECT,
        title: 'Для какой расы должны быть доступны черты?',
        propName: racePropName,
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любая'
          },
          ...raceListFiltered
            .map(
              ({ name: {plural: { nominative }}, id: value }) => ({ value, text: upLetter(nominative) })
            )
            .sort(sortByText)
        ]
      },
      {
        label: 'Подраса',
        type: FILTER_TYPE_SELECT,
        title: 'Для какой подрасы должны быть доступны черты?',
        propName: subRacePropName,
        value: DEFAULT_FILTER_VALUE,
        filterHandler: subRaceId => item => {
          if (subRaceId) {
            const {parentRaceId} = pcSubraceCollection[subRaceId]

            if (
              parentRaceId
              && item.requirementSubraceIdList
              && item.requirementRaceIdList
            ) {
              return (
                item.requirementSubraceIdList.includes(subRaceId)
                && item.requirementRaceIdList.includes(parentRaceId)
              )
            }

            return false
          }

          return true
        },
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любая'
          },
          ...subraceListFiltered
            .map(
              ({ name: {plural}, id: value }) => ({ value, text: upLetter(plural) })
            )
            .sort(sortByText)
        ]
      },
    ],
  },
  {
    header: 'Бонусы',
    children: [
      {
        label: 'Параметр',
        title: 'Какие черты повышают выбранный параметр на 1?',
        type: FILTER_TYPE_SELECT,
        propName: 'paramIncrease',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...paramFullList
            .map(
              ({ name: text, id, children = [] }) => ({
                text,
                value: [id, ...children],
              })
            )
            .sort(sortByText)
        ]
      },
      {
        label: 'Навык',
        title: 'Какие навыки даёт эта черта?',
        type: FILTER_TYPE_SELECT,
        propName: 'skillList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно'
          },
          ...skillListFiltered
            .map(
              skillId => ({
                text: skillCollection[skillId].name,
                value: skillId,
              })
            )
            .sort(sortByText)
        ]
      },
      {
        label: 'Инструмент',
        title: 'Владения какими инструментами даёт эта черта?',
        type: FILTER_TYPE_SELECT,
        propName: 'instrumentList',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно'
          },
          ...instrumentListFiltered
            .map(
              instrumentId => ({
                text: instrumentCollection[instrumentId].name,
                value: instrumentId,
              })
            )
            .sort(sortByText)
        ]
      },
    ],
  },
  {
    header: 'Переиздания',
    children: [
      {
        label: 'Отменено?',
        title: 'Черта существовала только в Раскопанных Тайнах и позже было решено её не издавать?',
        type: FILTER_TYPE_SELECT,
        propName: 'isAbandoned',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Переиздано?',
        title: 'Черта была переиздана в другой официальной версии',
        type: FILTER_TYPE_SELECT,
        propName: 'isReReleased',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  {
    label: 'Введите название черты или её текст',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
