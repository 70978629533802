import React from 'react'

import { featCollection } from '@/constants/featList'

import generateNameStr from '@/utils/generateNameStr'

import generateRequirementText from './utils/generateRequirementText'

import FeatComponent from './FeatComponent'

const FeatContainer = ({ id }) => {
  if (id) {
    const feat = featCollection[id]

    if (feat) {
      const { requirement, ...rest } = feat

      const requirementText = generateRequirementText(requirement)
      const {header, subHeader} = generateNameStr(feat)

      return <FeatComponent
        header={header}
        subHeader={subHeader}
        requirementText={requirementText}
        {...rest}
      />
    }
  }

  return null
}

export default FeatContainer
