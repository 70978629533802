import arrify from 'arrify'

import { paramCollection } from '@/constants/paramList'
import { pcRaceCollection } from '@/constants/pcRaceList'
import { pcClassCollection } from '@/constants/pcClassList'
import { pcSubraceCollection } from '@/constants/pcSubraceList'
import { featCollection } from '@/constants/featList'
import { featureCollection } from '@/constants/featureList'

import joinLastItem from '@/utils/joinLastItem'
import joinText from '@/utils/joinText'
import upLetter from '@/utils/upLetter'

const generateRequirementText = requirement => {
  if (!requirement) {
    return null
  }

  return upLetter(joinText(
    arrify(requirement)
      .reduce(
        (result, item) => {
          const requiredList = []

          if (typeof item === 'string') {
            requiredList.push(item)
          }

          if (typeof item === 'object') {
            if (item.lvl) {
              requiredList.push(`уровень ${item.lvl}`)
            }

            if (item.text) {
              requiredList.push(item.text)
            }

            if (item.feat) {
              const {id: featId, comment} = typeof item.feat === 'string'
                ? {id: item.feat}
                : item.feat
              const commentText = comment
                ? ` (${comment})`
                : ''
              requiredList.push(`черта «${featCollection[featId].name}»${commentText}`)
            }

            if (item.featureId) {
              requiredList.push(`способность «${featureCollection[item.featureId].name}»`)
            }

            if (item.params) {
              requiredList.push(
                joinLastItem(
                  Object
                    .entries(item.params)
                    .map(
                      ([paramId, paramValue]) => `${paramCollection[paramId].name} не менее ${paramValue}`
                    )
                )
              )
            }

            if (item.race || item.subrace) {
              let list = []

              if (item.race) {
                list = arrify(item.race).map(
                  raceId => pcRaceCollection[raceId].name.plural.nominative
                )
              }

              if (item.subrace) {
                list = arrify(item.subrace).map(
                  raceId => pcSubraceCollection[raceId].name.plural
                )
              }

              requiredList.push(
                joinLastItem(
                  list
                    .map(text => upLetter(text))
                    .sort(
                      (A, B) => A > B ? 1 : -1,
                    )
                )
              )
            }

            if (item.pcClassIdList) {
              requiredList.push(`класс ${joinLastItem(
                arrify(item.pcClassIdList)
                  .map(
                    pcClassId => upLetter(pcClassCollection[pcClassId].name.singular.genitive)
                  )
                  .sort(
                    (A, B) => A > B ? 1 : -1,
                  ),
                ', ',
                ' или '
                )}`
              )
            }
          }

          return [
            ...result,
            joinLastItem(requiredList),
          ]
        },
        []
      )
      .filter(e => e)
  ))
}

export default generateRequirementText
