const listToCollectionById = require('./../utils/listToCollectionById')
const { gearCollection } = require('./gearList')
const {
  GEAR_ALCHEMIST_S_SUPPLIES,
  GEAR_BREWERS_SUPPLIES,
  GEAR_CALLIGRAPHERS_SUPPLIES,
  GEAR_CARPENTER_S_TOOLS,
  GEAR_CARTOGRAPHER_S_TOOLS,
  GEAR_COBBLER_S_TOOLS,
  GEAR_COOK_S_UTENSILS,
  GEAR_DISGUISE_KIT,
  GEAR_FORGERY_KIT,
  GEAR_GLASSBLOWERS_TOOLS,
  GEAR_HERBALISM_KIT,
  GEAR_JEWELERS_TOOLS,
  GEAR_LEATHERWORKER_S_TOOLS,
  GEAR_MASONS_TOOLS,
  GEAR_NAVIGATORS_TOOLS,
  GEAR_PAINTERS_SUPPLIES,
  GEAR_POISONERS_KIT,
  GEAR_POTTERS_TOOLS,
  GEAR_SMITHS_TOOLS,
  GEAR_THIEVES_TOOLS,
  GEAR_TINKERS_TOOLS,
  GEAR_WEAVERS_TOOLS,
  GEAR_WOODCARVERS_TOOLS,
} = require('./gearIdList')

const INSTRUMENT_ANY = 'instrument_any'
const INSTRUMENT_ALCHEMISTS_SUPPLIES = GEAR_ALCHEMIST_S_SUPPLIES
const INSTRUMENT_BREWERS_SUPPLIES = GEAR_BREWERS_SUPPLIES
const INSTRUMENT_CALLIGRAPHERS_SUPPLIES = GEAR_CALLIGRAPHERS_SUPPLIES
const INSTRUMENT_CARPENTERS_TOOLS = GEAR_CARPENTER_S_TOOLS
const INSTRUMENT_CARTOGRAPHERS_TOOLS = GEAR_CARTOGRAPHER_S_TOOLS
const INSTRUMENT_COBBLERS_TOOLS = GEAR_COBBLER_S_TOOLS
const INSTRUMENT_COOKS_UTENSILS = GEAR_COOK_S_UTENSILS
const INSTRUMENT_DISGUISE_KIT = GEAR_DISGUISE_KIT
const INSTRUMENT_FORGERY_KIT = GEAR_FORGERY_KIT
const INSTRUMENT_GLASSBLOWERS_TOOLS = GEAR_GLASSBLOWERS_TOOLS
const INSTRUMENT_HERBALISM_KIT = GEAR_HERBALISM_KIT
const INSTRUMENT_JEWELERS_TOOLS = GEAR_JEWELERS_TOOLS
const INSTRUMENT_LEATHERWORKERS_TOOLS = GEAR_LEATHERWORKER_S_TOOLS
const INSTRUMENT_MASONS_TOOLS = GEAR_MASONS_TOOLS
const INSTRUMENT_NAVIGATORS_TOOLS = GEAR_NAVIGATORS_TOOLS
const INSTRUMENT_PAINTERS_SUPPLIES = GEAR_PAINTERS_SUPPLIES
const INSTRUMENT_POISONERS_KIT = GEAR_POISONERS_KIT
const INSTRUMENT_POTTERS_TOOLS = GEAR_POTTERS_TOOLS
const INSTRUMENT_SMITHS_TOOLS = GEAR_SMITHS_TOOLS
const INSTRUMENT_THIEVES_TOOLS = GEAR_THIEVES_TOOLS
const INSTRUMENT_TINKERS_TOOLS = GEAR_TINKERS_TOOLS
const INSTRUMENT_WEAVERS_TOOLS = GEAR_WEAVERS_TOOLS
const INSTRUMENT_WOODCARVERS_TOOLS = GEAR_WOODCARVERS_TOOLS

const instrumentList = [
  {
    id: INSTRUMENT_ANY,
    name: 'Любые инструменты',
    description: 'Любые инструменты',
    gearId: null,
  },
  {
    id: INSTRUMENT_ALCHEMISTS_SUPPLIES,
    name: gearCollection[INSTRUMENT_ALCHEMISTS_SUPPLIES].name,
    description: gearCollection[INSTRUMENT_ALCHEMISTS_SUPPLIES].description,
    gearId: INSTRUMENT_ALCHEMISTS_SUPPLIES,
  },
  {
    id: INSTRUMENT_BREWERS_SUPPLIES,
    name: gearCollection[INSTRUMENT_BREWERS_SUPPLIES].name,
    description: gearCollection[INSTRUMENT_BREWERS_SUPPLIES].description,
    gearId: INSTRUMENT_BREWERS_SUPPLIES,
  },
  {
    id: INSTRUMENT_CALLIGRAPHERS_SUPPLIES,
    name: gearCollection[INSTRUMENT_CALLIGRAPHERS_SUPPLIES].name,
    description: gearCollection[INSTRUMENT_CALLIGRAPHERS_SUPPLIES].description,
    gearId: INSTRUMENT_CALLIGRAPHERS_SUPPLIES,
  },
  {
    id: INSTRUMENT_CARPENTERS_TOOLS,
    name: gearCollection[INSTRUMENT_CARPENTERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_CARPENTERS_TOOLS].description,
    gearId: INSTRUMENT_CARPENTERS_TOOLS,
  },
  {
    id: INSTRUMENT_CARTOGRAPHERS_TOOLS,
    name: gearCollection[INSTRUMENT_CARTOGRAPHERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_CARTOGRAPHERS_TOOLS].description,
    gearId: INSTRUMENT_CARTOGRAPHERS_TOOLS,
  },
  {
    id: INSTRUMENT_COBBLERS_TOOLS,
    name: gearCollection[INSTRUMENT_COBBLERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_COBBLERS_TOOLS].description,
    gearId: INSTRUMENT_COBBLERS_TOOLS,
  },
  {
    id: INSTRUMENT_COOKS_UTENSILS,
    name: gearCollection[INSTRUMENT_COOKS_UTENSILS].name,
    description: gearCollection[INSTRUMENT_COOKS_UTENSILS].description,
    gearId: INSTRUMENT_COOKS_UTENSILS,
  },
  {
    id: INSTRUMENT_DISGUISE_KIT,
    name: gearCollection[INSTRUMENT_DISGUISE_KIT].name,
    description: gearCollection[INSTRUMENT_DISGUISE_KIT].description,
    gearId: INSTRUMENT_DISGUISE_KIT,
  },
  {
    id: INSTRUMENT_FORGERY_KIT,
    name: gearCollection[INSTRUMENT_FORGERY_KIT].name,
    description: gearCollection[INSTRUMENT_FORGERY_KIT].description,
    gearId: INSTRUMENT_FORGERY_KIT,
  },
  {
    id: INSTRUMENT_GLASSBLOWERS_TOOLS,
    name: gearCollection[INSTRUMENT_GLASSBLOWERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_GLASSBLOWERS_TOOLS].description,
    gearId: INSTRUMENT_GLASSBLOWERS_TOOLS,
  },
  {
    id: INSTRUMENT_HERBALISM_KIT,
    name: gearCollection[INSTRUMENT_HERBALISM_KIT].name,
    description: gearCollection[INSTRUMENT_HERBALISM_KIT].description,
    gearId: INSTRUMENT_HERBALISM_KIT,
  },
  {
    id: INSTRUMENT_JEWELERS_TOOLS,
    name: gearCollection[INSTRUMENT_JEWELERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_JEWELERS_TOOLS].description,
    gearId: INSTRUMENT_JEWELERS_TOOLS,
  },
  {
    id: INSTRUMENT_LEATHERWORKERS_TOOLS,
    name: gearCollection[INSTRUMENT_LEATHERWORKERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_LEATHERWORKERS_TOOLS].description,
    gearId: INSTRUMENT_LEATHERWORKERS_TOOLS,
  },
  {
    id: INSTRUMENT_MASONS_TOOLS,
    name: gearCollection[INSTRUMENT_MASONS_TOOLS].name,
    description: gearCollection[INSTRUMENT_MASONS_TOOLS].description,
    gearId: INSTRUMENT_MASONS_TOOLS,
  },
  {
    id: INSTRUMENT_NAVIGATORS_TOOLS,
    name: gearCollection[INSTRUMENT_NAVIGATORS_TOOLS].name,
    description: gearCollection[INSTRUMENT_NAVIGATORS_TOOLS].description,
    gearId: INSTRUMENT_NAVIGATORS_TOOLS,
  },
  {
    id: INSTRUMENT_PAINTERS_SUPPLIES,
    name: gearCollection[INSTRUMENT_PAINTERS_SUPPLIES].name,
    description: gearCollection[INSTRUMENT_PAINTERS_SUPPLIES].description,
    gearId: INSTRUMENT_PAINTERS_SUPPLIES,
  },
  {
    id: INSTRUMENT_POISONERS_KIT,
    name: gearCollection[INSTRUMENT_POISONERS_KIT].name,
    description: gearCollection[INSTRUMENT_POISONERS_KIT].description,
    gearId: INSTRUMENT_POISONERS_KIT,
  },
  {
    id: INSTRUMENT_POTTERS_TOOLS,
    name: gearCollection[INSTRUMENT_POTTERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_POTTERS_TOOLS].description,
    gearId: INSTRUMENT_POTTERS_TOOLS,
  },
  {
    id: INSTRUMENT_SMITHS_TOOLS,
    name: gearCollection[INSTRUMENT_SMITHS_TOOLS].name,
    description: gearCollection[INSTRUMENT_SMITHS_TOOLS].description,
    gearId: INSTRUMENT_SMITHS_TOOLS,
  },
  {
    id: INSTRUMENT_THIEVES_TOOLS,
    name: gearCollection[INSTRUMENT_THIEVES_TOOLS].name,
    description: gearCollection[INSTRUMENT_THIEVES_TOOLS].description,
    gearId: INSTRUMENT_THIEVES_TOOLS,
  },
  {
    id: INSTRUMENT_TINKERS_TOOLS,
    name: gearCollection[INSTRUMENT_TINKERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_TINKERS_TOOLS].description,
    gearId: INSTRUMENT_TINKERS_TOOLS,
  },
  {
    id: INSTRUMENT_WEAVERS_TOOLS,
    name: gearCollection[INSTRUMENT_WEAVERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_WEAVERS_TOOLS].description,
    gearId: INSTRUMENT_WEAVERS_TOOLS,
  },
  {
    id: INSTRUMENT_WOODCARVERS_TOOLS,
    name: gearCollection[INSTRUMENT_WOODCARVERS_TOOLS].name,
    description: gearCollection[INSTRUMENT_WOODCARVERS_TOOLS].description,
    gearId: INSTRUMENT_WOODCARVERS_TOOLS,
  },
]

module.exports = instrumentList

module.exports.instrumentCollection = listToCollectionById(instrumentList)

module.exports.INSTRUMENT_ANY = INSTRUMENT_ANY
module.exports.INSTRUMENT_ALCHEMISTS_SUPPLIES = INSTRUMENT_ALCHEMISTS_SUPPLIES
module.exports.INSTRUMENT_BREWERS_SUPPLIES = INSTRUMENT_BREWERS_SUPPLIES
module.exports.INSTRUMENT_CALLIGRAPHERS_SUPPLIES = INSTRUMENT_CALLIGRAPHERS_SUPPLIES
module.exports.INSTRUMENT_CARPENTERS_TOOLS = INSTRUMENT_CARPENTERS_TOOLS
module.exports.INSTRUMENT_CARTOGRAPHERS_TOOLS = INSTRUMENT_CARTOGRAPHERS_TOOLS
module.exports.INSTRUMENT_COBBLERS_TOOLS = INSTRUMENT_COBBLERS_TOOLS
module.exports.INSTRUMENT_COOKS_UTENSILS = INSTRUMENT_COOKS_UTENSILS
module.exports.INSTRUMENT_DISGUISE_KIT = INSTRUMENT_DISGUISE_KIT
module.exports.INSTRUMENT_FORGERY_KIT = INSTRUMENT_FORGERY_KIT
module.exports.INSTRUMENT_GLASSBLOWERS_TOOLS = INSTRUMENT_GLASSBLOWERS_TOOLS
module.exports.INSTRUMENT_HERBALISM_KIT = INSTRUMENT_HERBALISM_KIT
module.exports.INSTRUMENT_JEWELERS_TOOLS = INSTRUMENT_JEWELERS_TOOLS
module.exports.INSTRUMENT_LEATHERWORKERS_TOOLS = INSTRUMENT_LEATHERWORKERS_TOOLS
module.exports.INSTRUMENT_MASONS_TOOLS = INSTRUMENT_MASONS_TOOLS
module.exports.INSTRUMENT_NAVIGATORS_TOOLS = INSTRUMENT_NAVIGATORS_TOOLS
module.exports.INSTRUMENT_PAINTERS_SUPPLIES = INSTRUMENT_PAINTERS_SUPPLIES
module.exports.INSTRUMENT_POISONERS_KIT = INSTRUMENT_POISONERS_KIT
module.exports.INSTRUMENT_POTTERS_TOOLS = INSTRUMENT_POTTERS_TOOLS
module.exports.INSTRUMENT_SMITHS_TOOLS = INSTRUMENT_SMITHS_TOOLS
module.exports.INSTRUMENT_THIEVES_TOOLS = INSTRUMENT_THIEVES_TOOLS
module.exports.INSTRUMENT_TINKERS_TOOLS = INSTRUMENT_TINKERS_TOOLS
module.exports.INSTRUMENT_WEAVERS_TOOLS = INSTRUMENT_WEAVERS_TOOLS
module.exports.INSTRUMENT_WOODCARVERS_TOOLS = INSTRUMENT_WOODCARVERS_TOOLS
