const {
  SOURCE_MTG_CLB,
  SOURCE_PHB,
} = require('./../../sourceList')
const {
  FEAT_DUNGEON_DELVER,
  FEAT_ELDRITCH_ADEPT,
  FEAT_INSPIRING_LEADER,
  FEAT_RITUAL_CASTER,
  FEAT_SHARPSHOOTER,
  FEAT_TAVERN_BRAWLER,
  FEAT_TELEPATHIC,
} = require('./../../featIdList')

const Inspiring_leader_PHB_img = require('./../../../images/feats/phb/inspiring_leader.jpg')
const Ritual_caster_PHB_img = require('./../../../images/feats/phb/ritual_caster.jpg')

const Eldritch_adept_TCoE_img = require('./../../../images/feats/tcoe/eldritch_adept.png')
const Telepathic_TCoE_img = require('./../../../images/feats/tcoe/telepathic.png')

const Inspiring_leader_MTG_CLB_img = require('./../../../images/feats/mtg_clb/inspiring_leader.jpg')
const Dungeon_delver_MTG_CLB_img = require('./../../../images/feats/mtg_clb/dungeon_delver.jpg')
const Sharpshooter_MTG_CLB_img = require('./../../../images/feats/mtg_clb/sharpshooter_mtg_clb.webp')
const Tavern_brawler_MTG_CLB_img = require('./../../../images/feats/mtg_clb/tavern-brawler-mtg_clb.webp')

module.exports = {
  [FEAT_DUNGEON_DELVER]: {
    src: Dungeon_delver_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  [FEAT_SHARPSHOOTER]: {
    src: Sharpshooter_MTG_CLB_img,
    text: 'Эльфийский меткий стрелок',
    source: SOURCE_MTG_CLB,
  },
  [FEAT_INSPIRING_LEADER]: [
    {
      src: Inspiring_leader_PHB_img,
      source: {
        id: SOURCE_PHB,
      },
    },
    {
      src: Inspiring_leader_MTG_CLB_img,
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
  [FEAT_RITUAL_CASTER]: {
    src: Ritual_caster_PHB_img,
    source: {
      id: SOURCE_PHB,
    },
  },
  [FEAT_ELDRITCH_ADEPT]: {
    src: Eldritch_adept_TCoE_img,
    source: {
      id: SOURCE_PHB,
    },
  },
  [FEAT_TAVERN_BRAWLER]: {
    src: Tavern_brawler_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  [FEAT_TELEPATHIC]: {
    src: Telepathic_TCoE_img,
    text: 'Сидящая на единороге бардесса телепатически вдохновляет её товарища волшебника',
    source: {
      id: SOURCE_PHB,
    },
  },
}
