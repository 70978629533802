module.exports.FEAT_ACROBAT_2017_04_17 = 'acrobat_2017_04_17'
module.exports.FEAT_ACTOR = 'actor'
module.exports.FEAT_AGENT_OF_ORDER_2022_07_18 = 'agent_of_order_2022_07_18'
module.exports.FEAT_ALCHEMIST_2016_06_06 = 'alchemist_2016_06_06'
module.exports.FEAT_ALERT = 'alert'
module.exports.FEAT_ANIMAL_HANDLER_2017_04_17 = 'animal_handler_2017_04_17'
module.exports.FEAT_ARCANIST_2017_04_17 = 'arcanist_2017_04_17'
module.exports.FEAT_ARTIFICER_INITIATE = 'artificer_initiate'
module.exports.FEAT_ARTIFICER_INITIATE_2020_07_13 = 'artificer_initiate_2020_07_13'
module.exports.FEAT_ATHLETE = 'athlete'
module.exports.FEAT_BALEFUL_SCION_2022_07_18 = 'baleful_scion_2022_07_18'
module.exports.FEAT_BARBED_HIDE_2017_04_24 = 'barbed_hide_2017_04_24'
module.exports.FEAT_BLADE_MASTERY_2016_06_06 = 'blade_mastery_2016_06_06'
module.exports.FEAT_BOUNTIFUL_LUCK = 'bountiful_luck'
module.exports.FEAT_BRAWNY_2017_04_17 = 'brawny_2017_04_17'
module.exports.FEAT_BURGLAR_2016_06_06 = 'burglar_2016_06_06'
module.exports.FEAT_CARTOMANCER_2022_07_18 = 'cartomancer_2022_07_18'
module.exports.FEAT_CHARGER = 'charger'
module.exports.FEAT_CHEF = 'chef'
module.exports.FEAT_CRITTER_FRIEND_2017_04_24 = 'critter_friend_2017_04_24'
module.exports.FEAT_CROSSBOW_EXPERT = 'crossbow_expert'
module.exports.FEAT_CRUSHER = 'crusher'
module.exports.FEAT_CRUSHER_2020_07_13 = 'crusher_2020_07_13'
module.exports.FEAT_DEFENSIVE_DUELIST = 'defensive_duelist'
module.exports.FEAT_DIPLOMAT_2017_04_17 = 'diplomat_2017_04_17'
module.exports.FEAT_DRAGON_FEAR = 'dragon_fear'
module.exports.FEAT_DRAGON_HIDE = 'dragon_hide'
module.exports.FEAT_DRAGON_WINGS_2017_04_24 = 'dragon_wings'
module.exports.FEAT_DROW_HIGH_MAGIC = 'drow_high_magic'
module.exports.FEAT_DUAL_WIELDER = 'dual_wielder'
module.exports.FEAT_DUNGEON_DELVER = 'dungeon_delver'
module.exports.FEAT_DURABLE = 'durable'
module.exports.FEAT_DWARVEN_FORTITUDE = 'dwarven_fortitude'
module.exports.FEAT_ELDRITCH_ADEPT = 'eldritch_adept'
module.exports.FEAT_ELEMENTAL_ADEPT = 'elemental_adept'
module.exports.FEAT_ELVEN_ACCURACY = 'elven_accuracy'
module.exports.FEAT_EMPATHIC_2017_04_17 = 'empathic_2017_04_17'
module.exports.FEAT_EVERYBODYS_FRIEND_2017_04_24 = 'everybodys_friend_2017_04_24'
module.exports.FEAT_FADE_AWAY = 'fade_away'
module.exports.FEAT_FELL_HANDED_2016_06_06 = 'fell_handed_2016_06_06'
module.exports.FEAT_FEY_TELEPORTATION = 'fey_teleportation'
module.exports.FEAT_FEY_TOUCHED = 'fey_touched'
module.exports.FEAT_FIGHTING_INITIATE = 'fighting_initiate'
module.exports.FEAT_FIGHTING_INITIATE_2020_07_13 = 'fighting_initiate_2020_07_13'
module.exports.FEAT_FLAIL_MASTERY_2016_06_06 = 'flail_mastery_2016_06_06'
module.exports.FEAT_FLAMES_OF_PHLEGETHOS = 'flames_of_phlegethos'
module.exports.FEAT_GIFT_OF_THE_CHROMATIC_DRAGON_2021_04_14 = 'gift_of_the_chromatic_dragon_2021_04_14'
module.exports.FEAT_GIFT_OF_THE_GEM_DRAGON_2021_04_14 = 'gift_of_the_gem_dragon_2021_04_14'
module.exports.FEAT_GIFT_OF_THE_METALLIC_DRAGON_2021_04_14 = 'gift_of_the_metallic_dragon_2021_04_14'
module.exports.FEAT_GOURMAND_2016_06_06 = 'gourmand_2016_06_06'
module.exports.FEAT_GRAPPLER = 'grappler'
module.exports.FEAT_GREAT_WEAPON_MASTER = 'great_weapon_master'
module.exports.FEAT_GRUDGE_BEARER_2017_04_24 = 'grudge_bearer_2017_04_24'
module.exports.FEAT_GUNNER = 'gunner'
module.exports.FEAT_HEALER = 'healer'
module.exports.FEAT_HEAVILY_ARMORED = 'heavily_armored'
module.exports.FEAT_HEAVY_ARMOR_MASTER = 'heavy_armor_master'
module.exports.FEAT_HISTORIAN_2017_04_17 = 'historian_2017_04_17'
module.exports.FEAT_HUMAN_DETERMINATION_2017_04_24 = 'human_determination_2017_04_24'
module.exports.FEAT_INFERNAL_CONSTITUTION = 'infernal_module.exports.tution'
module.exports.FEAT_INSPIRING_LEADER = 'inspiring_leader'
module.exports.FEAT_INVESTIGATOR_2017_04_17 = 'investigator_2017_04_17'
module.exports.FEAT_KEEN_MIND = 'keen_mind'
module.exports.FEAT_LIGHTLY_ARMORED = 'lightly_armored'
module.exports.FEAT_LINGUIST = 'linguist'
module.exports.FEAT_LUCKY = 'lucky'
module.exports.FEAT_MAGE_SLAYER = 'mage_slayer'
module.exports.FEAT_MAGIC_INITIATE = 'magic_initiate'
module.exports.FEAT_MARTIAL_ADEPT = 'martial_adept'
module.exports.FEAT_MASTER_OF_DISGUISE_2016_06_06 = 'master_of_disguise_2016_06_06'
module.exports.FEAT_MEDIC_2017_04_17 = 'medic_2017_04_17'
module.exports.FEAT_MEDIUM_ARMOR_MASTER = 'medium_armor_master'
module.exports.FEAT_MENACING_2017_04_17 = 'menacing_2017_04_17'
module.exports.FEAT_METABOLIC_CONTROL_2020_04_14 = 'metabolic_control_2020_04_14'
module.exports.FEAT_METAMAGIC_ADEPT = 'metamagic_adept'
module.exports.FEAT_METAMAGIC_ADEPT_2020_07_13 = 'metamagic_adept_2020_07_13'
module.exports.FEAT_MOBILE = 'mobile'
module.exports.FEAT_MODERATELY_ARMORED = 'moderately_armored'
module.exports.FEAT_MOUNTED_COMBATANT = 'mounted_combatant'
module.exports.FEAT_NATURALIST_2017_04_17 = 'naturalist_2017_04_17'
module.exports.FEAT_OBSERVANT = 'observant'
module.exports.FEAT_ORCISH_AGGRESSION_2017_04_24 = 'orcish_aggression'
module.exports.FEAT_ORCISH_FURY = 'orcish_fury'
module.exports.FEAT_PERCEPTIVE_2017_04_17 = 'perceptive_2017_04_17'
module.exports.FEAT_PERFORMER_2017_04_17 = 'performer_2017_04_17'
module.exports.FEAT_PIERCER = 'piercer'
module.exports.FEAT_POISONER = 'poisoner'
module.exports.FEAT_POISONER_2020_07_13 = 'poisoner_2020_07_13'
module.exports.FEAT_POLEARM_MASTER = 'polearm_master'
module.exports.FEAT_PRACTICED_EXPERT_2020_07_13 = 'practiced_expert_2020_07_13'
module.exports.FEAT_PRODIGY = 'prodigy'
module.exports.FEAT_QUICK_FINGERED_2017_04_17 = 'quick_fingered_2017_04_17'
module.exports.FEAT_RESILIENT = 'resilient'
module.exports.FEAT_REVENANT_BLADE = 'revenant_blade'
module.exports.FEAT_RITUAL_CASTER = 'ritual_caster'
module.exports.FEAT_SAVAGE_ATTACKER = 'savage_attacker'
module.exports.FEAT_SCION_OF_THE_OUTER_PLANES_2022_07_18 = 'scion_of_the_outer_planes_2022_07_18'
module.exports.FEAT_SECOND_CHANCE = 'second_chance'
module.exports.FEAT_SENTINEL = 'sentinel'
module.exports.FEAT_SHADOW_TOUCHED = 'shadow_touched'
module.exports.FEAT_SHADOW_TOUCHED_2020_07_13 = 'shadow_touched_2020_07_13'
module.exports.FEAT_SHARPSHOOTER = 'sharpshooter'
module.exports.FEAT_SHIELD_MASTER = 'shield_master'
module.exports.FEAT_SHIELD_TRAINING_2020_07_13 = 'shield_training_2020_07_13'
module.exports.FEAT_SILVER_TONGUED_2017_04_17 = 'silver_tongued_2017_04_17'
module.exports.FEAT_SKILL_EXPERT = 'skill_expert'
module.exports.FEAT_SKILLED = 'skilled'
module.exports.FEAT_SKULKER = 'skulker'
module.exports.FEAT_SLASHER = 'slasher'
module.exports.FEAT_SPEAR_MASTERY_2016_06_06 = 'spear_mastery_2016_06_06'
module.exports.FEAT_SPELL_SNIPER = 'spell_sniper'
module.exports.FEAT_SQUAT_NIMBLENESS = 'squat_nimbleness'
module.exports.FEAT_STEALTHY_2017_04_17 = 'stealthy'
module.exports.FEAT_SURVIVALIST_2017_04_17 = 'survivalist_2017_04_17'
module.exports.FEAT_SVIRFNEBLIN_MAGIC = 'svirfneblin_magic'
module.exports.FEAT_TANDEM_TACTICIAN_2020_07_13 = 'tandem_tactician_2020_07_13'
module.exports.FEAT_TAVERN_BRAWLER = 'tavern_brawler'
module.exports.FEAT_TELEKINETIC = 'telekinetic'
module.exports.FEAT_TELEKINETIC_2019_11_25 = 'telekinetic_2019_11_25'
module.exports.FEAT_TELEKINETIC_2020_04_14 = 'telekinetic_2020_04_14'
module.exports.FEAT_TELEPATHIC = 'telepathic'
module.exports.FEAT_TELEPATHIC_2019_11_25 = 'telepathic_2019_11_25'
module.exports.FEAT_TELEPATHIC_2020_04_14 = 'telepathic_2020_04_14'
module.exports.FEAT_THEOLOGIAN_2017_04_17 = 'theologian_2017_04_17'
module.exports.FEAT_TOUGH = 'tough'
module.exports.FEAT_TOWER_OF_IRON_WILL_2020_04_14 = 'tower_of_iron_will_2020_04_14'
module.exports.FEAT_TRACKER_2020_07_13 = 'tracker_2020_07_13'
module.exports.FEAT_WAR_CASTER = 'war_caster'
module.exports.FEAT_WEAPON_MASTER = 'weapon_master'
module.exports.FEAT_WILD_TALENT_2020_04_14 = 'wild_talent_2020_04_14'
module.exports.FEAT_WONDER_MAKER_2017_04_24 = 'wonder_maker_2017_04_24'
module.exports.FEAT_WOOD_ELF_MAGIC = 'wood_elf_magic'
