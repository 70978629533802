import React from 'react'

import Feat from '@/components/Feat'
import FeatCatalog from '@/components/FeatCatalog'

import {featCollection} from "@/constants/featList"
import featImageCollection from "@/constants/images/featImageCollection"

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Черты',
  description: 'Каталог всех черт в Dungeons & Dragons',
}

const FeatPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={featCollection}
    ItemComponent={Feat}
    itemImageCollection={featImageCollection}
    ListComponent={FeatCatalog}
    {...props}
  />
)

export default FeatPageTemplate
