import React from 'react'

import featList from '@/constants/featList'
import Catalog from '@/components/Catalog'

import generateFeatPageUrlById from "@/utils/generateFeatPageUrlById"

import filterListOriginal from './constants/filterList'

const FeatCatalogContainer = () => {
  const itemList = featList.filter(({isReady}) => isReady)

  return (
    <Catalog
      pageUrlGenerator={generateFeatPageUrlById}
      pageTitle='Каталог черт'
      itemNameList={itemList}
      itemList={itemList}
      filterListOriginal={filterListOriginal}
    />
  )
}

export default FeatCatalogContainer
