const {
  SOURCE_ERFTLW,
  SOURCE_ERRATA_PHB,
  SOURCE_PHB,
  SOURCE_SCAG,
  SOURCE_SRD,
  SOURCE_TCoE,
  SOURCE_UA_2016_06_06_FEATS,
  SOURCE_UA_2017_04_24_FFR,
  SOURCE_UA_2017_04_17_FFS,
  SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
  SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
  SOURCE_UA_2020_07_13_FEATS,
  SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
  SOURCE_UA_2022_07_18_WotM,
  SOURCE_XGTE,
} = require('./../sourceList')
const {
  PARAM_ANY,
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} = require('./../paramList')
const {
  SKILL_ACROBATICS,
  SKILL_ANIMAL_HANDLING,
  SKILL_ANY,
  SKILL_ARCANA,
  SKILL_ATHLETICS,
  SKILL_DECEPTION,
  SKILL_HISTORY,
  SKILL_INSIGHT,
  SKILL_INTIMIDATION,
  SKILL_INVESTIGATION,
  SKILL_MEDICINE,
  SKILL_NATURE,
  SKILL_PERCEPTION,
  SKILL_PERFORMANCE,
  SKILL_PERSUASION,
  SKILL_RELIGION,
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
  SKILL_SURVIVAL,
} = require('./../skillList')
const {
  INSTRUMENT_ANY,
  INSTRUMENT_ALCHEMISTS_SUPPLIES,
  INSTRUMENT_COOKS_UTENSILS,
  INSTRUMENT_DISGUISE_KIT,
  INSTRUMENT_POISONERS_KIT,
  INSTRUMENT_THIEVES_TOOLS,
  INSTRUMENT_TINKERS_TOOLS,
} = require('./../instrumentList')
const {
  GEAR_ALCHEMIST_S_SUPPLIES,
  GEAR_BATTLEAXE,
  GEAR_BLOCK_AND_TACKLE,
  GEAR_COOK_S_UTENSILS,
  GEAR_CROSSBOW_HAND,
  GEAR_DISGUISE_KIT,
  GEAR_DOUBLE_BLADED_SCIMITAR,
  GEAR_FLAIL,
  GEAR_GLAIVE,
  GEAR_GREATAXE,
  GEAR_GREATSWORD,
  GEAR_HALBERD,
  GEAR_HANDAXE,
  GEAR_LONGSWORD,
  GEAR_MAUL,
  GEAR_PIKE,
  GEAR_POISONERS_KIT,
  GEAR_QUARTERSTAFF,
  GEAR_RAPIER,
  GEAR_SCIMITAR,
  GEAR_SHIELD,
  GEAR_SHORTSWORD,
  GEAR_SPEAR,
  GEAR_THIEVES_TOOLS,
  GEAR_TINKERS_TOOLS,
  GEAR_WARHAMMER,
} = require('./../gearIdList')
const {
  CREATURE_BARBED_DEVIL,
  CREATURE_GNOLL,
  CREATURE_ORC,
} = require('./../creatureIdList')
const {
  PC_SUBRACE_ELF_DROW,
  PC_SUBRACE_ELF_WOOD,
  PC_SUBRACE_ELF_MOON,
  PC_SUBRACE_ELF_SUN,
  PC_SUBRACE_GNOME_FOREST,
  PC_SUBRACE_GNOME_ROCK,
  PC_SUBRACE_GNOME_DEEP,
} = require('./../pcSubraceIdList')
const {
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_TIEFLING,
} = require('./../pcRaceIdList')
const {
  pcSmallRaceIdList,
} = require('./../pcRaceList')
const {
  SPELL_ALARM,
  SPELL_ANIMAL_FRIENDSHIP,
  SPELL_BLINDNESS_DEAFNESS,
  SPELL_BLUR,
  SPELL_CHILL_TOUCH,
  SPELL_CURE_WOUNDS,
  SPELL_DARKNESS,
  SPELL_DETECT_EVIL_AND_GOOD,
  SPELL_DETECT_MAGIC,
  SPELL_DETECT_POISON_AND_DISEASE,
  SPELL_DETECT_THOUGHTS,
  SPELL_DISGUISE_SELF,
  SPELL_DISPEL_MAGIC,
  SPELL_DRUIDCRAFT,
  SPELL_GUIDANCE,
  SPELL_HUNTERS_MARK,
  SPELL_INVISIBILITY,
  SPELL_LEVITATE,
  SPELL_LONGSTRIDER,
  SPELL_MAGE_HAND,
  SPELL_MESSAGE,
  SPELL_MINOR_ILLUSION,
  SPELL_MISTY_STEP,
  SPELL_NONDETECTION,
  SPELL_PASS_WITHOUT_TRACE,
  SPELL_PRESTIDIGITATION,
  SPELL_SACRED_FLAME,
  SPELL_SPEAK_WITH_ANIMALS,
  SPELL_THAUMATURGY,
} = require('./../spellIdList')
const {
  PC_CLASS_ARTIFICER,
  PC_CLASS_BARD,
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
  PC_CLASS_FIGHTER,
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../pcClassIdList')
const {
  FEATURE_COMBAT_SUPERIORITY,
  FEATURE_METAMAGIC,
  FEATURE_PACT_MAGIC,
  FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14,
  FEATURE_SORCERY_POINTS,
} = require('./../featureIdList')
const {
  FEAT_ACROBAT_2017_04_17,
  FEAT_ACTOR,
  FEAT_AGENT_OF_ORDER_2022_07_18,
  FEAT_ALCHEMIST_2016_06_06,
  FEAT_ALERT,
  FEAT_ANIMAL_HANDLER_2017_04_17,
  FEAT_ARCANIST_2017_04_17,
  FEAT_ARTIFICER_INITIATE,
  FEAT_ARTIFICER_INITIATE_2020_07_13,
  FEAT_ATHLETE,
  FEAT_BALEFUL_SCION_2022_07_18,
  FEAT_BARBED_HIDE_2017_04_24,
  FEAT_BLADE_MASTERY_2016_06_06,
  FEAT_BOUNTIFUL_LUCK,
  FEAT_BRAWNY_2017_04_17,
  FEAT_BURGLAR_2016_06_06,
  FEAT_CARTOMANCER_2022_07_18,
  FEAT_CHARGER,
  FEAT_CHEF,
  FEAT_CRITTER_FRIEND_2017_04_24,
  FEAT_CROSSBOW_EXPERT,
  FEAT_CRUSHER,
  FEAT_CRUSHER_2020_07_13,
  FEAT_DEFENSIVE_DUELIST,
  FEAT_DIPLOMAT_2017_04_17,
  FEAT_DRAGON_FEAR,
  FEAT_DRAGON_HIDE,
  FEAT_DRAGON_WINGS_2017_04_24,
  FEAT_DROW_HIGH_MAGIC,
  FEAT_DUAL_WIELDER,
  FEAT_DUNGEON_DELVER,
  FEAT_DURABLE,
  FEAT_DWARVEN_FORTITUDE,
  FEAT_ELDRITCH_ADEPT,
  FEAT_ELEMENTAL_ADEPT,
  FEAT_ELVEN_ACCURACY,
  FEAT_EMPATHIC_2017_04_17,
  FEAT_EVERYBODYS_FRIEND_2017_04_24,
  FEAT_FADE_AWAY,
  FEAT_FELL_HANDED_2016_06_06,
  FEAT_FEY_TELEPORTATION,
  FEAT_FEY_TOUCHED,
  FEAT_FIGHTING_INITIATE,
  FEAT_FIGHTING_INITIATE_2020_07_13,
  FEAT_FLAIL_MASTERY_2016_06_06,
  FEAT_FLAMES_OF_PHLEGETHOS,
  FEAT_GIFT_OF_THE_CHROMATIC_DRAGON_2021_04_14,
  FEAT_GIFT_OF_THE_GEM_DRAGON_2021_04_14,
  FEAT_GIFT_OF_THE_METALLIC_DRAGON_2021_04_14,
  FEAT_GOURMAND_2016_06_06,
  FEAT_GRAPPLER,
  FEAT_GREAT_WEAPON_MASTER,
  FEAT_GRUDGE_BEARER_2017_04_24,
  FEAT_GUNNER,
  FEAT_HEALER,
  FEAT_HEAVILY_ARMORED,
  FEAT_HEAVY_ARMOR_MASTER,
  FEAT_HISTORIAN_2017_04_17,
  FEAT_HUMAN_DETERMINATION_2017_04_24,
  FEAT_INFERNAL_CONSTITUTION,
  FEAT_INSPIRING_LEADER,
  FEAT_INVESTIGATOR_2017_04_17,
  FEAT_KEEN_MIND,
  FEAT_LIGHTLY_ARMORED,
  FEAT_LINGUIST,
  FEAT_LUCKY,
  FEAT_MAGE_SLAYER,
  FEAT_MAGIC_INITIATE,
  FEAT_MARTIAL_ADEPT,
  FEAT_MASTER_OF_DISGUISE_2016_06_06,
  FEAT_MEDIC_2017_04_17,
  FEAT_MEDIUM_ARMOR_MASTER,
  FEAT_MENACING_2017_04_17,
  FEAT_METABOLIC_CONTROL_2020_04_14,
  FEAT_METAMAGIC_ADEPT,
  FEAT_METAMAGIC_ADEPT_2020_07_13,
  FEAT_MOBILE,
  FEAT_MODERATELY_ARMORED,
  FEAT_MOUNTED_COMBATANT,
  FEAT_NATURALIST_2017_04_17,
  FEAT_OBSERVANT,
  FEAT_ORCISH_AGGRESSION_2017_04_24,
  FEAT_ORCISH_FURY,
  FEAT_PERCEPTIVE_2017_04_17,
  FEAT_PERFORMER_2017_04_17,
  FEAT_PIERCER,
  FEAT_POISONER,
  FEAT_POISONER_2020_07_13,
  FEAT_POLEARM_MASTER,
  FEAT_PRACTICED_EXPERT_2020_07_13,
  FEAT_PRODIGY,
  FEAT_QUICK_FINGERED_2017_04_17,
  FEAT_RESILIENT,
  FEAT_REVENANT_BLADE,
  FEAT_RITUAL_CASTER,
  FEAT_SAVAGE_ATTACKER,
  FEAT_SCION_OF_THE_OUTER_PLANES_2022_07_18,
  FEAT_SECOND_CHANCE,
  FEAT_SENTINEL,
  FEAT_SHADOW_TOUCHED,
  FEAT_SHADOW_TOUCHED_2020_07_13,
  FEAT_SHARPSHOOTER,
  FEAT_SHIELD_MASTER,
  FEAT_SHIELD_TRAINING_2020_07_13,
  FEAT_SILVER_TONGUED_2017_04_17,
  FEAT_SKILL_EXPERT,
  FEAT_SKILLED,
  FEAT_SKULKER,
  FEAT_SLASHER,
  FEAT_SPEAR_MASTERY_2016_06_06,
  FEAT_SPELL_SNIPER,
  FEAT_SQUAT_NIMBLENESS,
  FEAT_STEALTHY_2017_04_17,
  FEAT_SURVIVALIST_2017_04_17,
  FEAT_SVIRFNEBLIN_MAGIC,
  FEAT_TANDEM_TACTICIAN_2020_07_13,
  FEAT_TAVERN_BRAWLER,
  FEAT_TELEKINETIC,
  FEAT_TELEKINETIC_2019_11_25,
  FEAT_TELEKINETIC_2020_04_14,
  FEAT_TELEPATHIC,
  FEAT_TELEPATHIC_2019_11_25,
  FEAT_TELEPATHIC_2020_04_14,
  FEAT_THEOLOGIAN_2017_04_17,
  FEAT_TOUGH,
  FEAT_TOWER_OF_IRON_WILL_2020_04_14,
  FEAT_TRACKER_2020_07_13,
  FEAT_WAR_CASTER,
  FEAT_WEAPON_MASTER,
  FEAT_WILD_TALENT_2020_04_14,
  FEAT_WONDER_MAKER_2017_04_24,
  FEAT_WOOD_ELF_MAGIC,
} = require('./../featIdList')

module.exports = [
  {
    id: FEAT_ACTOR,
    name: `Артистичный`,
    nameEn: `Actor`,
    description: `Научившись театральному искусству и подражанию, Вы получаете следующие преимущества:

* Увеличьте значение Харизмы на 1, при максимуме 20.
* Вы совершаете с преимуществом проверки Харизмы (Выступление) и Харизма (Обман), когда пытаетесь выдать себя за кого-то другого.
* Вы можете подражать речи кого-то другого, а также звукам, издаваемым другими существами. Перед этим Вы должны как минимум в течение 1 минуты слышать чужую речь или звуки существа. Успешная проверка Мудрости (Проницательность), противопоставленная Вашей проверке Харизмы (Обман), позволяет слушающему понять, что источник звука не настоящий.`,
    paramIncrease: PARAM_CHA,
    source: {
      id: SOURCE_PHB,
      page: 166,
    },
  },
  {
    id: FEAT_ATHLETE,
    name: `Атлетичный`,
    nameEn: `Athlete`,
    description: `Вы прошли интенсивную физическую подготовку и получаете следующие преимущества:

* Увеличьте значение Силы или Ловкости на 1, при максимуме 20.
* Если Вы лежите ничком, вставание использует только 5 футов перемещения.
* Лазание не заставляет Вас тратить дополнительное перемещение.
* Вы можете совершать прыжок в длину или высоту с разбега, переместившись только на 5 футов, а не на 10.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: {
      id: SOURCE_PHB,
      page: 166,
    },
  },
  {
    id: FEAT_ALERT,
    name: `Бдительный`,
    nameEn: `Alert`,
    description: `Вы всегда готовы к опасностям и получаете следующие преимущества:

* Вы получаете бонус +5 к проверкам инициативы.
* Вас не могут застать врасплох, пока Вы в сознании.
* Другие существа не получают преимущество для бросков атаки по Вам за то, что Вы их не видите.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 166,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь не «скрытые от Вас существа», а «не видимые Вами существа»',
        page: 2,
      },
    ],
  },
  {
    id: FEAT_WAR_CASTER,
    name: `Боевой заклинатель`,
    nameEn: `War Caster`,
    requirement: `Способность сотворять хотя бы одно заклинание`,
    description: `Вы научились сотворять заклинания в пылу сражения и узнали техники, дающие следующие преимущества:

* Вы с преимуществом проходите испытания Телосложения для поддержания концентрации на заклинании при получении урона.
* Вы можете выполнять телесный компонент заклинаний даже если используете оружие или щит в одной или обеих руках.
* Если перемещение враждебного существа провоцирует от Вас атаку, Вы можете вместо совершения провоцированной атаки реакцией наложить на это существо заклинание. Время накладывания этого заклинания должно быть «1 действие» и оно должно нацеливаться только на это существо.`,
    source: {
      id: SOURCE_PHB,
      page: 166,
    },
  },
  {
    id: FEAT_GRAPPLER,
    name: `Борец`,
    nameEn: `Grappler`,
    requirement: {
      params: {
        [PARAM_STR]: 13,
      },
    },
    description: `Вы развили навыки, нужные для тесного захвата противников. Вы получаете следующие преимущества:

* Вы совершаете с преимуществом броски атаки по существу, которое держите в захвате.
* Вы можете действием попытаться скрутить захваченное Вами существо. Для этого совершите ещё одну проверку захвата. При успехе и Вы и это существо становитесь обездвиженными до окончания захвата.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 166,
      },
      {
        id: SOURCE_SRD,
        page: 75,
      },
    ],
  },
  {
    id: FEAT_LUCKY,
    name: `Везунчик`,
    nameEn: `Lucky`,
    description: `Вам непонятным образом везёт как раз тогда, когда это нужно.

У Вас есть 3 единицы удачи. Каждый раз, когда Вы совершаете бросок атаки, проверку характеристики или проходите испытание, Вы можете потратить одну единицу удачи, чтобы бросить дополнительный к20. Вы можете решить потратить единицу удачи после обычного броска кости, но до определения последствий. После этого Вы сами выбираете, какую к20 использовать для броска атаки, проверки характеристики или испытания.

Вы также можете потратить одну единицу удачи, когда по Вам совершается бросок атаки. Бросьте к20, а потом решите, какую из костей будет использовать атакующий, Вашу или свою. Если сразу несколько существ тратят единицы удачи для оказания влияния на один бросок, единицы отменяют друг друга и дополнительные кости никто не кидает.

Вы восстанавливаете потраченные единицы удачи в конце длинного отдыха.`,
    source: {
      id: SOURCE_PHB,
      page: 166,
    },
  },
  {
    id: FEAT_MOUNTED_COMBATANT,
    name: `Верховой боец`,
    nameEn: `Mounted Combatant`,
    description: `Вы — опасный враг, когда Вы верхом на скакуне. Если Вы находитесь верхом и не выведены из строя, Вы получаете следующие преимущества:

* Вы совершаете с преимуществом броски рукопашных атак по существам, чей размер меньше Вашего скакуна, и не находящимся при этом верхом.
* Вы можете сделать так, что атака, нацеленная на Вашего скакуна, будет перенацелена на Вас.
* Если Ваш скакун попадает под эффект, позволяющий пройти испытание Ловкости для получения половины урона, то он не получает урон в случае успеха и получает всего половину урона при провале.`,
    source: {
      id: SOURCE_PHB,
      page: 167,
    },
  },
  {
    id: FEAT_OBSERVANT,
    name: `Внимательный`,
    nameEn: `Observant`,
    description: `Вы быстро улавливаете мелкие подробности и получаете следующие преимущества:

* Увеличьте значение Интеллекта или Мудрости на 1, при максимуме 20.
* Если Вы видите рот существа, когда оно говорит на языке, который Вы понимаете, Вы можете прочитать по его губам, что оно говорит.
* Вы получаете бонус +5 к пассивной проверке Мудрости (Внимательность) и пассивной проверке Интеллекта (Анализ).`,
    paramIncrease: PARAM_INT,
    source: {
      id: SOURCE_PHB,
      page: 167,
    },
  },
  {
    id: FEAT_MARTIAL_ADEPT,
    name: `Воинский адепт`,
    nameEn: `Martial Adept`,
    description: `Вы прошли военную подготовку, позволяющую совершать особые боевые приёмы. Вы получаете следующие преимущества:

* Вы узнаёте два приёма на свой выбор из списка архетипа воина [Мастер боевых искусств](FEATURE:${FEATURE_COMBAT_SUPERIORITY}). Если приём требует, чтобы цель прошла испытание для сопротивления эффекту приёма, СЛ испытания равна 8 + Ваш бонус мастерства + модификатор Силы или Ловкости (на Ваш выбор).
* Вы получаете одну Кость превосходства: к6 (она добавляется к другим Костям превосходства, которые Вы можете получить от других источников). Эта кость подпитывает Ваши приёмы. Кость превосходства тратится, когда Вы её используете, и восстанавливается после окончания короткого или длинного отдыха. Эта Кость превосходства останется у Вас даже если позже Вы получите новые кости из другого источника.`,
    source: [
      {
        id: SOURCE_PHB,
        page: 167,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь Кость превосходства от этой черты всегда к6 :-(',
        page: 2,
      },
    ],
  },
  {
    id: FEAT_INSPIRING_LEADER,
    name: `Воодушевляющий лидер`,
    nameEn: `Inspiring Leader`,
    requirement: {
      params: {
        [PARAM_CHA]: 13,
      },
    },
    description: `Вы можете потратить 10 минут на воодушевление спутников, укрепляя их решимость.

Если Вы делаете это, выберите до шести дружественных существ (можете включить себя) в пределах 30 футов от себя, которые могут видеть или слышать Вас, и которые могут Вас понять. Все эти существа получают временные хиты в количестве, равном Вашему уровню + Ваш модификатор Харизмы.

Существа больше не могут получать временные хиты от этой черты, пока не окончат короткий или длинный отдых.`,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_SAVAGE_ATTACKER,
    name: `Дикий атакующий`,
    nameEn: `Savage Attacker`,
    description: `Один раз в ход, когда Вы совершаете бросок урона для атаки рукопашным оружием, Вы можете перебросить все кости урона оружия и использовать любой из вариантов.`,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_TAVERN_BRAWLER,
    name: `Драчун`,
    nameEn: `Tavern Brawler`,
    description: `Привыкнув к мордобою с использованием подручных предметов, Вы получаете следующие преимущества:

* Увеличьте значение Силы или Телосложения на 1, при максимуме 20.
* Вы получаете владение импровизированным оружием.
* Ваш безоружный удар использует для урона к4.
* Если Вы в свой ход попадаете по существу безоружным ударом или импровизированным оружием, Вы можете бонусным действием попытаться захватить цель.`,
    note: `Единственное, что Вы любите больше, чем хорошую выпивку — это хорошую драку. К счастью, за одним, как правило, следует другое.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_CON,
    ],
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_LIGHTLY_ARMORED,
    name: `Знаток лёгких доспехов`,
    nameEn: `Lightly Armored`,
    description: `Вы обучились ношению лёгких доспехов и получаете следующие преимущества:

* Увеличьте значение Силы или Ловкости на 1, при максимуме 20.
* Вы получаете владение лёгкими доспехами.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_MODERATELY_ARMORED,
    name: `Знаток средних доспехов`,
    nameEn: `Moderately Armored`,
    requirement: `Владение лёгкими доспехами`,
    description: `Вы обучились ношению средних доспехов и щитов, и получаете следующие преимущества:

* Увеличьте значение Силы или Ловкости на 1, при максимуме 20.
* Вы получаете владение средними доспехами и щитами.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_HEAVILY_ARMORED,
    name: `Знаток тяжёлых доспехов`,
    nameEn: `Heavily Armored`,
    requirement: `Владение средними доспехами`,
    description: `Вы обучились ношению тяжёлых доспехов и получаете следующие преимущества:

* Увеличьте значение Силы на 1, при максимуме 20.
* Вы получаете владение тяжёлыми доспехами.`,
    paramIncrease: PARAM_STR,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_DUAL_WIELDER,
    name: `Использование двух оружий`,
    nameEn: `Dual Wielder`,
    description: `Вы знаете как использовать сразу два оружия, и получаете следующие преимущества:

* Вы получаете бонус +1 к КД, когда держите в каждой руке по рукопашному оружию.
* Вы можете использовать сражение с двумя оружиями даже если ни у одного из Ваших одноручных оружий нет свойства «лёгкое».
* Вы можете вынимать и убирать два одноручных оружия когда обычно позволяется вынуть или убрать только одно.`,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_DUNGEON_DELVER,
    name: `Исследователь подземелий`,
    nameEn: `Dungeon Delver`,
    description: `Научившись искать скрытые ловушки и потайные двери в подземельях, Вы получаете следующие преимущества:

* Вы совершаете с преимуществом проверки Мудрости (Внимательность) и Интеллекта (Анализ), совершённые для обнаружения потайных дверей.
* Вы с преимуществом проходите испытания для избегания ловушек и сопротивления их эффектам.
* Вы получаете сопротивление к урону от ловушек.
* Передвижение быстрым темпом больше не накладывает штраф −5 на Вашу пассивную Внимательность`,
    source: [
      {
        id: SOURCE_PHB,
        page: 168,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Добавлено четвёртое преимущество',
        page: 2,
      },
    ],
  },
  {
    id: FEAT_DURABLE,
    name: `Крепкий`,
    nameEn: `Durable`,
    description: `Вы стойкий и живучий, и получаете следующие преимущества:

* Увеличьте значение Телосложения на 1, при максимуме 20.
* Когда Вы бросаете Кость Хитов для восстановления хитов, минимум равен удвоенному модификатору Телосложения (минимум 2).`,
    paramIncrease: PARAM_CON,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_HEALER,
    name: `Лекарь`,
    nameEn: `Healer`,
    description: `Вы способный медик, что позволяет быстро залечивать раны и возвращать союзников в строй. Вы получаете следующие преимущества:

* Когда Вы используете комплект целителя для стабилизации умирающего существа, это существо также восстанавливает 1 хит.
* Вы можете действием потратить одно использование комплекта целителя, чтобы позаботиться о существе и восстановить ему 1к6 + 4 хита, плюс дополнительные хиты, равные максимуму имеющихся у него Костей Хитов. Это существо не сможет повторно восстанавливать хиты от этой черты, пока не закончит короткий или длинный отдых.`,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_GREAT_WEAPON_MASTER,
    name: `Мастер большого оружия`,
    nameEn: `Great Weapon Master`,
    description: `Вы научились использовать вес своего оружия, позволяя инерции усиливать Ваши атаки. Вы получаете следующие преимущества:

* В свой ход, когда Вы совершаете критическое попадание рукопашным оружием или опускаете им хиты существа до 0, Вы можете бонусным действием совершить одну атаку рукопашным оружием.
* Перед совершением атаки рукопашным оружием со свойством «тяжёлое», которым Вы владеете, Вы можете принять штраф −5 к броску атаки. Если такая атака попадает, Вы добавляете +10 к урону от этой атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 168,
    },
  },
  {
    id: FEAT_POLEARM_MASTER,
    name: `Мастер древкового оружия`,
    nameEn: `Polearm Master`,
    description: `Вы можете сдерживать врагов оружием со свойством «досягаемость». Вы получаете следующие преимущества:

* Если Вы совершаете действие Атака и атакуете только [алебардой](GEAR:${GEAR_HALBERD}), [боевым посохом](GEAR:${GEAR_QUARTERSTAFF}) или [глефой](GEAR:${GEAR_GLAIVE}), Вы можете бонусным действием совершить рукопашную атаку противоположным концом оружия. Кость урона для этой атаки равна к4, и атака причиняет дробящий урон. Бонусная атака использует модификатор той же характеристики, что и основная.
* Если Вы используете [алебарду](GEAR:${GEAR_HALBERD}), [боевой посох](GEAR:${GEAR_QUARTERSTAFF}), [глефу](GEAR:${GEAR_GLAIVE}) или [пику](GEAR:${GEAR_PIKE}), другие существа провоцируют от Вас атаку, когда входят в пределы Вашей досягаемости.`,
    source: {
      id: SOURCE_PHB,
      page: 169,
    },
  },
  {
    id: FEAT_WEAPON_MASTER,
    name: `Мастер оружия`,
    nameEn: `Weapon Master`,
    description: `Вы знаете как пользоваться множеством видов оружия и получаете следующие преимущества:

* Увеличьте значение Силы или Ловкости на 1, при максимуме 20.
* Вы получаете владение четырьмя выбранными видами оружия. Выбранное оружие должно быть или простым или воинским.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: {
      id: SOURCE_PHB,
      page: 169,
    },
  },
  {
    id: FEAT_MEDIUM_ARMOR_MASTER,
    name: `Мастер средних доспехов`,
    nameEn: `Medium Armor Master`,
    requirement: `Владение средними доспехами`,
    description: `Вы привыкли к перемещению в средних доспехах и получаете следующие преимущества:

* Ношение среднего доспеха не накладывает помеху к проверкам Ловкости (Скрытность).
* Когда Вы носите средний доспех, Вы можете добавлять к КД 3, а не 2, если Ваша Ловкость 16 или выше.`,
    source: {
      id: SOURCE_PHB,
      page: 169,
    },
  },
  {
    id: FEAT_HEAVY_ARMOR_MASTER,
    name: `Мастер тяжёлых доспехов`,
    nameEn: `Heavy Armor Master`,
    requirement: `Владение тяжёлыми доспехами`,
    description: `Вы можете своим доспехом отклонять удары, которые других убили бы. Вы получаете следующие преимущества:

* Увеличьте значение Силы на 1, при максимуме 20.
* Если Вы носите тяжёлый доспех, дробящий, колющий и рубящий урон, получаемый Вами от немагического оружия, уменьшается на 3.`,
    paramIncrease: PARAM_STR,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_SHIELD_MASTER,
    name: `Мастер щитов`,
    nameEn: `Shield Master`,
    description: `Вы используете [щиты](GEAR:${GEAR_SHIELD}) не только для обороны, но и для нападения. Вы получаете следующие преимущества, когда используете [щит](GEAR:${GEAR_SHIELD}):

* Если Вы в свой ход совершаете действие Атака, Вы можете бонусным действием попытаться оттолкнуть [щитом](GEAR:${GEAR_SHIELD}) существо, находящееся в пределах 5 футов от Вас.
* Если Вы не выведены из строя, Вы можете добавлять бонус к КД от [щита](GEAR:${GEAR_SHIELD}) ко всем испытаниям Ловкости, совершённым от заклинаний и прочих вредоносных эффектов, нацеленных только на Вас.
* Если Вы подвергаетесь действию эффекта, позволяющего пройти испытание Ловкости для получения половины урона, Вы можете реакцией вообще не получить урон в случае успешного испытания, выставив [щит](GEAR:${GEAR_SHIELD}) между собой и источником эффекта.`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_SPELL_SNIPER,
    name: `Меткие заклинания`,
    nameEn: `Spell Sniper`,
    requirement: `Способность сотворять хотя бы одно заклинание`,
    description: `Вы узнали технику, улучшающую атаку некоторыми видами заклинаний, и получаете следующие преимущества:

* Если Вы сотворяете заклинание, требующее совершение броска атаки, дистанция заклинания удваивается.
* Ваши дальнобойные атаки заклинаниями игнорируют укрытие на половину и укрытие на три четверти.
* Вы узнаёте один заговор, требующий броска атаки. Выберите заговор из списка заклинаний [барда](PC_CLASS:${PC_CLASS_BARD}), [волшебника](PC_CLASS:${PC_CLASS_WIZARD}), [друида](PC_CLASS:${PC_CLASS_DRUID}), [жреца](PC_CLASS:${PC_CLASS_CLERIC}), [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}) или [чародея](PC_CLASS:${PC_CLASS_SORCERER}). Базовая характеристика для этого заговора зависит от выбранного класса
  * Харизма для [барда](PC_CLASS:${PC_CLASS_BARD}), [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}) и [чародея](PC_CLASS:${PC_CLASS_SORCERER});
  * Интеллект для [волшебника](PC_CLASS:${PC_CLASS_WIZARD});
  * Мудрость для [друида](PC_CLASS:${PC_CLASS_DRUID}) и [жреца](PC_CLASS:${PC_CLASS_CLERIC}).`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_SHARPSHOOTER,
    name: `Меткий стрелок`,
    nameEn: `Sharpshooter`,
    description: `Вы овладели дальнобойным оружием и можете совершать выстрелы, которые другие считали невозможными. Вы получаете следующие преимущества:

* Совершение атаки в пределах максимальной дистанции не вызывает помеху к броску атаки дальнобойным оружием.
* Ваши атаки дальнобойным оружием игнорируют укрытие на половину и укрытие на три четверти.
* Перед совершением атаки дальнобойным оружием, которым Вы владеете, Вы можете принять штраф −5 к броску атаки. Если такая атака попадает, Вы добавляете +10 к урону от этой атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_CHARGER,
    name: `Налётчик`,
    nameEn: `Charger`,
    description: `Когда Вы используете действие Рывок, Вы можете бонусным действием совершить одну рукопашную атаку оружием или оттолкнуть другое существо.

Если Вы перед совершением этого бонусного действия переместились минимум на 10 футов по прямой линии, Вы либо получаете бонус +5 к броску урона этой атаки (если Вы решили совершить рукопашную атаку и попали), либо толкаете цель на 10 футов от себя (если решили толкать и преуспели).`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_DEFENSIVE_DUELIST,
    name: `Оборонительный дуэлянт`,
    nameEn: `Defensive Duelist`,
    requirement: {
      params: {
        [PARAM_DEX]: 13,
      },
    },
    description: `Если Вы используете оружие со свойством «фехтовальное», которым владеете, и другое существо попадает по Вам рукопашной атакой, Вы можете для этой атаки реакцией добавить бонус мастерства к КД, что потенциально может привести к промаху атаки.`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_SKILL_EXPERT,
    name: `Эксперт в навыках`,
    nameEn: `Skill Expert`,
    description: `Вы отточили мастерство в определённых навыках, получая получаете следующие преимущества:

* Увеличьте значение любой характеристики на 1, при максимуме 20.
* Вы получаете владение одним навыком по Вашему выбору.
* Выберите один навык, которым уже владеете. Вы получаете компетентность в этом навыке, что удваивает Ваш бонус мастерства для любой проверки характеристик с использованием этого навыка. Навык, который Вы выбираете, не может быть уже удвоен от другой особенности, такой как Компетентность.`,
    paramIncrease: PARAM_ANY,
    source: {
      id: SOURCE_TCoE,
      page: 121,
    },
  },
  {
    id: FEAT_SKILLED,
    name: `Одарённый`,
    nameEn: `Skilled`,
    description: `Вы получаете владение любой комбинацией из трёх навыков и инструментов на Ваш выбор.`,
    skillList: SKILL_ANY,
    instrumentList: INSTRUMENT_ANY,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_KEEN_MIND,
    name: `Отличная память`,
    nameEn: `Keen Mind`,
    description: `Вы с невероятной точностью можете отслеживать время, направление и подробности. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта на 1, при максимуме 20.
* Вы всегда знаете, в какой стороне находится север.
* Вы всегда знаете, через сколько часов наступит следующий закат или восход.
* Вы можете с точностью вспомнить всё, что видели или слышали за последний месяц.`,
    paramIncrease: PARAM_INT,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_MOBILE,
    name: `Подвижный`,
    nameEn: `Mobile`,
    description: `Вы невероятно быстры и ловки. Вы получаете следующее преимущество:

* Ваша скорость увеличивается на 10 футов.
* Когда Вы используете действие Рывок, труднопроходимая местность в этом ходу не замедляет Ваше перемещение.
* Если Вы совершаете рукопашную атак по существу, Вы до конца этого хода не провоцируете от него атаки, вне зависимости от того, попадёте или нет.`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_MAGIC_INITIATE,
    name: `Начинающий маг`,
    nameAlt: `Посвящённый в магию`,
    nameEn: `Magic Initiate`,
    description: `Выберите класс: [бард](PC_CLASS:${PC_CLASS_BARD}), [волшебник](PC_CLASS:${PC_CLASS_WIZARD}), [друид](PC_CLASS:${PC_CLASS_DRUID}), [жрец](PC_CLASS:${PC_CLASS_CLERIC}), [колдун](PC_CLASS:${PC_CLASS_WARLOCK}) или [чародей](PC_CLASS:${PC_CLASS_SORCERER}).

Вы узнаёте два заговора на свой выбор из списка заклинаний этого класса. Кроме того, выберите одно заклинание 1 уровня из этого же списка. Вы узнаёте это заклинание и можете сотворять его минимально возможным кругом. После сотворения этого заклинания этой чертой Вы должны закончить длинный отдых, прежде чем сможете сотворить его снова.

Базовая характеристика для этих заклинаний зависит от выбранного класса: Харизма для [барда](PC_CLASS:${PC_CLASS_BARD}), [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}) и [чародея](PC_CLASS:${PC_CLASS_SORCERER}); Интеллект для [волшебника](PC_CLASS:${PC_CLASS_WIZARD}); Мудрость для [друида](PC_CLASS:${PC_CLASS_DRUID}) и [жреца](PC_CLASS:${PC_CLASS_CLERIC}).`,
    source: {
      id: SOURCE_PHB,
      page: 170,
    },
  },
  {
    id: FEAT_SKULKER,
    name: `Проныра`,
    nameEn: `Skulker`,
    requirement: {
      params: {
        [PARAM_DEX]: 13,
      },
    },
    description: `Вы знаете, как сливаться с тенями. Вы получаете следующие преимущества:

* Вы можете попытаться спрятаться даже если слабо заслонены от существа, от которого прячетесь.
* Если Вы прячетесь от существа и промахиваетесь по нему атакой дальнобойным оружием, эта атака не выдаёт Вашу позицию.
* Тусклый свет не причиняет помеху Вашим проверкам Мудрости (Внимательность), полагающимся на зрение.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_RITUAL_CASTER,
    name: `Ритуальный заклинатель`,
    nameEn: `Ritual Caster`,
    requirement: [
      {
        params: {
          [PARAM_INT]: 13,
        },
      },
      {
        params: {
          [PARAM_WIT]: 13,
        },
      },
    ],
    description: `Вы узнали несколько заклинаний, которые можете сотворять как ритуалы. Эти заклинания записаны в ритуальной книге, которую Вы должны держать в руке во время их использования.

Когда Вы выбираете эту черту, Вы получаете ритуальную книгу, содержащую два заклинания 1 уровня на Ваш выбор. Выберите один из классов: [бард](PC_CLASS:${PC_CLASS_BARD}), [волшебник](PC_CLASS:${PC_CLASS_WIZARD}), [друид](PC_CLASS:${PC_CLASS_DRUID}), [жрец](PC_CLASS:${PC_CLASS_CLERIC}), [колдун](PC_CLASS:${PC_CLASS_WARLOCK}) или [чародей](PC_CLASS:${PC_CLASS_SORCERER}). Вы должны выбирать заклинания из списков для этого класса, но у этих заклинаний должно быть ключевое слово «ритуал». Выбранный класс также определяет базовую характеристику для этих заклинаний: Харизма для [барда](PC_CLASS:${PC_CLASS_BARD}), [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}) и [чародея](PC_CLASS:${PC_CLASS_SORCERER}); Интеллект для [волшебника](PC_CLASS:${PC_CLASS_WIZARD}); Мудрость для [друида](PC_CLASS:${PC_CLASS_DRUID}) и [жреца](PC_CLASS:${PC_CLASS_CLERIC}).

Найдя заклинание, записанное в письменной форме, такой как магический свиток заклинания или книга заклинаний волшебника, Вы можете скопировать его в свою ритуальную книгу. Это заклинание должно быть в списке заклинаний выбранного Вами класса, уровень его не должен превышать половину Вашего уровня (округлённую в большую сторону), и у него должно быть ключевое слово _«ритуал»_. Процесс переписывания занимает по 2 часа за каждый круг заклинания и стоит по 50 зм за каждый уровень. Это стоимость материальных компонентов, расходуемых в процессе экспериментов с заклинанием, а также особых чернил, которыми оно записывается.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_ELEMENTAL_ADEPT,
    name: `Стихийный адепт`,
    nameEn: `Elemental Adept`,
    requirement: `Способность сотворять хотя бы одно заклинание`,
    description: `Когда Вы получаете эту черту, выберите один из видов урона: звук, кислота, огонь, холод или электричество. Накладываемые Вами заклинания игнорируют сопротивление к выбранному виду урона. Кроме того, когда Вы определяете урон от наложенного Вами заклинания, причиняющего урон этого вида, Вы можете считать все выпавшие на костях «1» как «2».

Вы можете брать эту черту несколько раз. Каждый раз, когда Вы это делаете, Вы выбираете новый вид урона.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_TOUGH,
    name: `Стойкий`,
    nameEn: `Tough`,
    description: `Максимум Ваших хитов увеличивается на количество, равное удвоенному уровню, на котором берётся эта черта.

Каждый раз, когда Вы впоследствии будете получать уровень, максимум Ваших хитов будет дополнительно увеличиваться на 2.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_TOWER_OF_IRON_WILL_2020_04_14,
    name: `Крепость железной воли`,
    nameEn: `Tower of Iron Will`,
    description: `Защита Вашего разума крепка.

После того, как Вы или другое видимое Вами в пределах 30 футов существо проваливает испытание, Вы можете реакцией совершить бросок своей кости Псионических талантов и добавить результат к броску, что потенциально может привести к успеху.`,
    requirement: [
      {featureId: FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14},
      {feat: FEAT_WILD_TALENT_2020_04_14},
    ],
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 9,
    },
  },
  {
    id: FEAT_WILD_TALENT_2020_04_14,
    name: `Дикий талант`,
    nameEn: `Wild Talent`,
    description: `Вы пробудили свой псионический потенциал, что улучшает Ваше тело или разум. Увеличьте значение любой характеристики на 1, при максимуме 20.

Кроме того, внутри Вас проявился источник псионической силы. Эта энергия прибывает и убывает, когда Вы её направляете. Вы получаете кость Псионических талантов, которая представляет вашу псионическую силу. Начальный размер кости — к6.

**Варианты псионических талантов.** Вы можете использовать свою кость Псионических талантов следующими способами

* **Пси-улучшение характеристики.** Когда Вы совершаете проверку характеристики, увеличенной этой чертой, Вы можете бросить свою кость Псионических талантов и добавить выпавший результат к проверке. Вы можете решить сделать это до или после броска, но до объявления его результатов.

* **Пси-направленный удар.** Один раз на каждом своём ходу, когда Вы попадаете атакой, использующей характеристику, увеличенную этой чертой, Вы можете бросить свою кость Псионических талантов после броска костей урона и заменить результат любой кости урона на результат броска кости Псионических талантов.

**Изменение размера кости.** Если Вы выбросили максимальное значение на кости Псионических талантов, после этого броска она уменьшается на один размер. Это представляет выгорание Вашей псионической силы. Например, если кость — к6 и на ней выпало 6, она становится к4. Если она к4 и на ней выпало 4, она становится недоступной до окончание длинного отдыха.

И наоборот, когда на кости Псионических талантов выпадает 1 — после броска она увеличивается на один размер, вплоть до начального размера. Это представляет то, как Вы сохраняете псионическую энергию для дальнейшего использования. Например, если кость — к4 и на ней выпало 1, она становится к6.

Когда Вы завершаете длинный отдых, Ваша кость Псионических талантов сбрасывается к начальному размеру. Когда Вы достигаете определённого уровня, начальный размер кости увеличивается: на 5 уровне (к8), 11 уровне (к10) и 17 уровне (к12).

Если Вы уже получили кость Псионических талантов, такого как классовая способность, то Вы не получаете больше одной кости. Используйте ту кость, чей начальный размер больше. 

**Восстановление пси.** Бонусным действием Вы можете успокоить свой разум, восстановив свою кость Псионических талантов до начального размера. Вы не можете снова использовать эту способность, пока не завершите длинный отдых.`,
    paramIncrease: PARAM_ANY,
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 9,
    },
  },
  {
    id: FEAT_SENTINEL,
    name: `Страж`,
    nameEn: `Sentinel`,
    description: `Вы овладели техникой, позволяющей пользоваться всеми брешами в обороне противника, и получаете следующие преимущества:

* Если Вы попадаете по существу провоцированной атакой, скорость этого существа падает до 0 до конца текущего хода.
* Существа перед выходом из Вашей досягаемости провоцируют от Вас атаки, даже если совершают действие Отход.
* Если существо, находящееся в пределах 5 футов от Вас, совершает атаку не по Вам (и у цели нет этой черты), Вы можете реакцией совершить атаку рукопашным оружием по атакующему.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_MAGE_SLAYER,
    name: `Убийца магов`,
    nameEn: `Mage Slayer`,
    description: `Вы разработали техники, полезные в рукопашном сражении с заклинателями, и получаете следующие преимущества:

* Если существо в пределах 5 футов от Вас использует заклинание, Вы можете реакцией совершить атаку рукопашным оружием по этому существу.
* Когда Вы причиняете урон существу, концентрирующемуся на заклинании, это существо совершает с помехой испытание для поддержания концентрации.
* Вы с преимуществом проходите испытания от заклинаний, наложенных существами, находящимися в пределах 5 футов от Вас.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_RESILIENT,
    name: `Устойчивый`,
    nameEn: `Resilient`,
    description: `Выберите одну характеристику. Вы получаете следующие преимущества:

* Увеличьте значение выбранной характеристики на 1, при максимуме 20.
* Вы получаете владение испытаниями этой характеристики.`,
    paramIncrease: PARAM_ANY,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_CROSSBOW_EXPERT,
    name: `Эксперт в арбалетах`,
    nameEn: `Crossbow Expert`,
    description: `Благодаря обширной практике с арбалетом Вы получаете следующие преимущества:

* Вы игнорируете свойство _«перезарядка»_ у арбалетов, которыми владеете.
* Нахождение в пределах 5 футов от враждебного существа не накладывает помеху на Ваши броски дальнобойных атак.
* Когда Вы используете действие Атака и атакуете одноручным оружием, Вы можете бонусным действием атаковать [ручным арбалетом](GEAR:${GEAR_CROSSBOW_HAND}), находящимся в руке.`,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_LINGUIST,
    name: `Языковед`,
    nameEn: `Linguist`,
    description: `Вы изучали языки и способы кодирования текстов, и получаете следующие преимущества:

* Увеличьте значение Интеллекта на 1, при максимуме 20.
* Вы узнаёте три языка на свой выбор.
* Вы можете писать шифрованные письма. Другие не могут расшифровать код, пока либо Вы не научите их, либо они не преуспеют в проверке Интеллекта (Сл равна Вашему значению Интеллекта + Ваш бонус Мастерства), либо они не используют для расшифровки магию.`,
    paramIncrease: PARAM_INT,
    source: {
      id: SOURCE_PHB,
      page: 171,
    },
  },
  {
    id: FEAT_BOUNTIFUL_LUCK,
    name: `Бездонная удача`,
    nameEn: `Bountiful Luck`,
    description: `Ваш народ обладает исключительной удачей, которой Вы научились мистическим образом делиться со своими компаньонами, когда видите, что они не справляются. Вы не понимаете, как это работает, Вы просто хотите, и это происходит. Определенно это знак покровительства удачи!

Когда союзник, которого Вы видите в пределах 30 футов от Вас, выбрасывает 1 на к20 для броска атаки, проверки способности или испытания, Вы можете использовать реакцию и позволить союзнику перебросить кубик. Союзник обязан использовать новый результат.

После использования этой способности Вы не можете использовать Вашу расовую особенность _Удачливый_ до конца Вашего следующего хода.`,
    requirement: {
      race: PC_RACE_HALFLING,
    },
    source: {
      id: SOURCE_XGTE,
      page: 75,
    },
  },
  {
    id: FEAT_SECOND_CHANCE,
    name: `Второй шанс`,
    nameEn: `Second Chance`,
    description: `Удача улыбается Вам, когда кто-то пытается ударить Вас. Вы получаете следующие преимущества:

* Увеличьте значение Ловкости, Телосложения или Харизмы на 1 при максимуме 20.
* Когда существо, которое Вы видите, попадает по Вам броском атаки, Вы можете использовать реакцию, чтобы заставить врага перебросить этот бросок. После применения этой способности Вы не сможете использовать её вновь до броска инициативы в начале боя либо пока не завершите короткий или длинный отдых.`,
    requirement: {
      race: PC_RACE_HALFLING,
    },
    paramIncrease: [
      PARAM_DEX,
      PARAM_CON,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 75,
    },
  },
  {
    id: FEAT_PRODIGY,
    name: `Вундеркинд`,
    nameEn: `Prodigy`,
    description: `У Вас дар к освоению новых знаний. Вы получаете следующие преимущества:

* Вы получаете владение одним навыком на выбор, владение одним набором инструментов на выбор и обучаетесь бегло говорить на одном языке по выбору.
* Выберите один навык, которым уже владеете. Вы получаете компетентность в этом навыке, что удваивает Ваш бонус мастерства для любой проверки характеристик с использованием этого навыка. Навык, который Вы выбираете, не может быть уже удвоен от другой особенности, такой как Компетентность.`,
    requirement: {
      race: [
        PC_RACE_HALF_ELF,
        PC_RACE_HALF_ORC,
        PC_RACE_HUMAN,
      ],
    },
    skillList: SKILL_ANY,
    instrumentList: INSTRUMENT_ANY,
    source: {
      id: SOURCE_XGTE,
      page: 75,
    },
  },
  {
    id: FEAT_DROW_HIGH_MAGIC,
    name: `Высшая магия дроу`,
    nameEn: `Drow High Magic`,
    description: `Вы изучаете магию, присущую тёмным эльфам.

Вы изучаете заклинание [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}) и можете свободно сотворять его без затрат ячеек заклинаний. Вы также изучаете заклинания [Левитация] (SPELL:${SPELL_LEVITATE}) и [Рассеивание магии](SPELL:${SPELL_DISPEL_MAGIC}), каждое из которых Вы можете сотворить один раз без затрат ячеек заклинаний.

Вы восстанавливаете возможность сотворять эти два заклинания таким способом после длинного отдыха. Харизма — базовая характеристика для этих трёх заклинаний.`,
    requirement: {
      race: PC_RACE_ELF,
      subrace: PC_SUBRACE_ELF_DROW,
    },
    source: {
      id: SOURCE_XGTE,
      page: 75,
    },
  },
  {
    id: FEAT_DWARVEN_FORTITUDE,
    name: `Дварфийская стойкость`,
    nameEn: `Dwarven Fortitude`,
    description: `В Ваших венах течет кровь героев дварфов. Вы получаете следующие преимущества:

* Увеличьте значение Телосложения на 1 при максимуме 20.
* Когда в битве Вы используете действие Уклонение, Вы можете потратить кость хитов, чтобы вылечить себя. Бросьте кость, добавьте модификатор Телосложения и восстановите хиты в количестве, равном полученному значению (минимум 1).`,
    requirement: {
      race: PC_RACE_DWARF,
    },
    paramIncrease: PARAM_CON,
    source: {
      id: SOURCE_XGTE,
      page: 75,
    },
  },
  {
    id: FEAT_DRAGON_FEAR,
    name: `Драконий страх`,
    nameEn: `Dragon Fear`,
    description: `Когда Вы злитесь, то излучаете угрозу. Вы получаете следующие преимущества:

* Увеличьте значение Силы, Телосложения или Харизмы на 1 при максимуме 20.
* Вместо выдоха разрушительной энергии, Вы можете использовать Вашу расовую особенность _Оружие Дыхания_, чтобы издать рык, заставляя всех выбранных Вами существ в пределах 30 футов от Вас пройти испытание Мудрости (Сл 8 + бонус мастерства + модификатор Харизмы). Цель автоматически преуспевает в испытании, если она не может слышать или видеть Вас. При провале броска цель становится напуганной Вами на 1 минуту. Если напуганная цель получает урон, то она может повторить испытание, оканчивая эффект на себе при успехе.`,
    requirement: {
      race: PC_RACE_DRAGONBORN,
    },
    paramIncrease: [
      PARAM_STR,
      PARAM_CON,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_DRAGON_HIDE,
    name: `Драконья шкура`,
    nameEn: `Dragon Hide`,
    description: `У Вас появляются чешуя и когти, напоминающие таковые у Вашего драконьего предка. Вы получаете следующие преимущества:

* Увеличьте значение Силы, Телосложения или Харизмы на 1 при максимуме 20.
* Ваша чешуя становится твёрже. Пока Вы не носите броню, Ваш КД равен 13 + модификатор Ловкости. Вы можете использовать щит, продолжая получать это преимущество.
* Вы отращиваете втягивающиеся когти на кончиках пальцев. Вытягивание или втягивание их не требует траты каких-либо действий. Эти когти являются естественным оружием, которым Вы можете совершать безоружные удары. Если Вы попадёте такой атакой, то наносите 1к4 + модификатор Силы рубящего урона вместо обычного дробящего урона для безоружного удара.`,
    requirement: {
      race: PC_RACE_DRAGONBORN,
    },
    paramIncrease: [
      PARAM_STR,
      PARAM_CON,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_INFERNAL_CONSTITUTION,
    name: `Инфернальное телосложение`,
    nameEn: `Infernal Constitution`,
    description: `Кровь исчадий сильна в Вас и даёт Вам сопротивления, которыми обладают некоторые исчадия. Вы получаете следующие преимущества:

* Увеличьте значение Телосложения на 1 при максимуме 20.
* Вы получаете сопротивление к урону холодом и ядом.
* Вы получаете преимущество на испытания против эффектов, делающих Вас отравленным.`,
    requirement: {
      race: PC_RACE_TIEFLING,
    },
    paramIncrease: PARAM_CON,
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_FADE_AWAY,
    name: `Исчезновение`,
    nameEn: `Fade Away`,
    description: `Ваш народ искусно владеет магией иллюзий. Вы выучили магический трюк, чтобы исчезать, когда Вам наносят урон. Вы получаете следующие преимущества:

* Увеличьте значение Ловкости или Интеллекта на 1 при максимуме 20.
* Сразу же после получения урона Вы можете использовать реакцию, чтобы стать невидимым до конца своего следующего хода, либо пока не совершите атаку, не нанесете урон или не заставите кого-нибудь проходить испытание. Использовав эту способность, Вы не сможете использовать её вновь, пока не совершите короткий или длинный отдых.`,
    requirement: {
      race: PC_RACE_GNOME,
    },
    paramIncrease: [
      PARAM_DEX,
      PARAM_INT,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_WOOD_ELF_MAGIC,
    name: `Магия лесных эльфов`,
    nameEn: `Wood Elf Magic`,
    description: `Вы изучаете магию первобытных лесов, которые почитаются и защищаются Вашим народом.

Вы изучаете один заговор [друидов](PC_CLASS:${PC_CLASS_DRUID}) на Ваш выбор.

Вы также изучаете заклинания [Скороход](SPELL:${SPELL_LONGSTRIDER}) и [Бесследное передвижение](SPELL:${SPELL_PASS_WITHOUT_TRACE}), каждое из которых Вы можете сотворить один раз не тратя ячейку заклинаний.

Вы восстанавливаете способность сотворять их таким образом в конце длинного отдыха. Мудрость — базовая характеристика для этих трёх заклинаний.`,
    requirement: {
      race: PC_RACE_ELF,
      subrace: PC_SUBRACE_ELF_WOOD,
    },
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_SQUAT_NIMBLENESS,
    name: `Низкорослое проворство`,
    nameEn: `Squat Nimbleness`,
    description: `Вы необычайно проворны для Вашей расы. Вы получаете следующие выгоды:

* Увеличьте значение Силы или Ловкости на 1 при максимуме 20.
* Ваша скорость увеличивается на 5 футов.
* Вы получаете владение _Акробатикой_ или _Атлетикой_ (на выбор).
* Вы получаете преимущество на броски Силы (Атлетика) или Ловкости (Акробатика), которые делаете, чтобы выбраться из захвата.`,
    requirement: {
      race: [
        PC_RACE_DWARF,
        ...pcSmallRaceIdList,
      ],
    },
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    skillList: [
      SKILL_ACROBATICS,
      SKILL_ATHLETICS,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_ORCISH_FURY,
    name: `Орочье буйство`,
    nameEn: `Orcish Fury`,
    description: `Ваша ярость неукротимо горит внутри. Вы получаете следующие преимущества:

* Увеличьте значение Силы или Телосложения на 1 при максимуме 20.
* Когда Вы попадаете атакой простым или воинским оружием, Вы можете кинуть одну кость урона оружия ещё раз и добавить результат в качестве дополнительного урона того же типа, что и у оружия. Использовав эту способность, Вы не можете использовать её вновь, пока не завершите короткий или длинный отдых.
* Сразу же после использования расовой особенности _Непоколебимая Стойкость_ Вы можете реакцией совершить одну атаку оружием.`,
    requirement: {
      race: PC_RACE_HALF_ORC,
    },
    paramIncrease: [
      PARAM_STR,
      PARAM_CON,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_FLAMES_OF_PHLEGETHOS,
    name: `Пламя Флегетоса`,
    nameEn: `Flames of Phlegethos`,
    description: `Вы научились призывать адский огонь, и он Вас слушается. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта или Харизмы на 1 при максимуме 20.
* Когда Вы делаете бросок урона огнём, который наносит Ваше заклинание, Вы можете перебросить любые 1 на костях огненного урона, но должны использовать новые результаты, даже если они тоже будут 1.
* Когда Вы сотворяете заклинание, которое наносит урон огнём, Вы можете заставить пламя окутать Вас до конца Вашего следующего хода. Огонь не причиняет урон Вам и Вашим вещам, а также излучает яркий свет на 30 футов и тусклый свет еще на 30 футов. Пока пламя существует, все существа в радиусе 5 футов получают 1к4 урона огнём, если попадают по Вам рукопашной атакой.`,
    requirement: {
      race: PC_RACE_TIEFLING,
    },
    paramIncrease: [
      PARAM_INT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 76,
    },
  },
  {
    id: FEAT_FEY_TELEPORTATION,
    name: `Телепортация фей`,
    nameEn: `Fey Teleportation`,
    description: `Изучение знаний высших эльфов открыло Вам силу фей, которой владеют немногие эльфы, за исключением Ваших собратьев эладринов. Опираясь на свое родство с феями, Вы можете на мгновение пройти через Страну Фей, сократив свой путь. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта или Харизмы на 1 при максимуме 20.
* Вы изучаете как читать, говорить и писать на Сильване.
* Вы изучаете заклинание [Туманный шаг](SPELL:${SPELL_MISTY_STEP}) и можете сотворить его один раз без использования ячейки заклинания. Вы восстанавливаете способность сотворять его таким образом, когда завершаете короткий или длинный отдых. Интеллект — базовая характеристика для этого заклинания.`,
    requirement: {
      race: PC_RACE_ELF,
      subrace: [
        PC_SUBRACE_ELF_MOON,
        PC_SUBRACE_ELF_SUN,
      ],
    },
    paramIncrease: [
      PARAM_INT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 77,
    },
  },
  {
    id: FEAT_ELVEN_ACCURACY,
    name: `Эльфийская точность`,
    nameEn: `Elven Accuracy`,
    description: `Точность эльфов легендарна, особенно это касается эльфийских лучников и заклинателей. Вы обладаете сверхъестественной меткостью, когда дело касается атак, требующих меткости, а не грубой силы. Вы получаете следующие преимущества:

* Увеличьте значение Ловкости, Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Когда у Вас есть преимущество к броску атаки, использующей Ловкость, Интеллект, Мудрость или Харизму, Вы можете перебросить один из кубов один раз.`,
    requirement: {
      race: [
        PC_RACE_HALF_ELF,
        PC_RACE_ELF,
      ],
    },
    paramIncrease: [
      PARAM_DEX,
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_XGTE,
      page: 77,
    },
  },
  {
    id: FEAT_ACROBAT_2017_04_17,
    name: `Акробат`,
    nameEn: `Acrobat`,
    description: `Вы становитесь ловчее, получая следующие преимущества:

* Увеличьте значение Ловкости на 1 при максимуме 20.
* Вы получаете владение _Акробатикой_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Бонусным действием Вы можете совершить проверку Ловкости (Акробатика) СЛ 15. При успехе труднопроходимая местность не будет стоить Вам дополнительного передвижения до конца текущего хода.`,
    paramIncrease: [
      PARAM_DEX,
    ],
    skillList: SKILL_ACROBATICS,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 1,
    },
  },
  {
    id: FEAT_ANIMAL_HANDLER_2017_04_17,
    name: `Дрессировщик`,
    nameEn: `Animal Handler`,
    description: `Вы овладеваете техникой обучения и обращения с животными. Вы получаете следующие преимущества.

* Увеличьте значение Мудрости на 1 при максимуме 20.
* Вы получаете владение _Обращением с животными_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* В свой ход бонусным действием Вы можете командовать одним дружелюбным зверем в пределах 60 футов от Вас, который может Вас слышать и сейчас не следует чьим-то ещё командам. Вы решаете, какое действие он совершит и куда переместится в своём следующем ходу, или же Вы можете отдать общий приказ, которому он будет следовать 1 минуту, например, охранять определённую область.`,
    paramIncrease: [
      PARAM_WIT,
    ],
    skillList: SKILL_ANIMAL_HANDLING,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 1,
    },
  },
  {
    id: FEAT_ARCANIST_2017_04_17,
    name: `Заклинатель`,
    nameEn: `Arcanist`,
    description: `Вы изучаете тайные искусства, получая следующие преимущества:

* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение _Магией_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Вы изучаете заклинания [Фокусы](SPELL:${SPELL_PRESTIDIGITATION}) и [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}). Вы можете сотворить [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}) один раз без использования ячейки заклинания. Вы восстанавливаете способность сотворять его таким образом, когда завершаете длинный отдых.`,
    paramIncrease: [
      PARAM_INT,
    ],
    skillList: SKILL_ARCANA,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 1,
    },
  },
  {
    id: FEAT_BRAWNY_2017_04_17,
    name: `Дюжий`,
    nameEn: `Brawny`,
    description: `Вы становитесь сильнее, получая следующие преимущества:

* Увеличьте значение Силы на 1 при максимуме 20.
* Вы получаете владение Атлетикой. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Вы считаетесь на один размер больше, когда определяется Ваша грузоподъемность.`,
    paramIncrease: [
      PARAM_STR,
    ],
    skillList: SKILL_ATHLETICS,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 1,
    },
  },
  {
    id: FEAT_DIPLOMAT_2017_04_17,
    name: `Дипломат`,
    nameEn: `Diplomat`,
    description: `Вы овладели искусством дипломатии, получая следующие преимущества:

* Увеличьте значение Харизмы на 1 при максимуме 20.
* Вы получаете владение _Убеждением_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Если Вы потратите 1 минуту на разговор с кем-то, кто может понять то, что Вы говорите, Вы можете совершите проверку Харизмы (Убеждение) против проверки Мудрости (Проницательность) этого существа. Если Вы или Ваши спутники сражаетесь с ним, проверка автоматически проваливается. При успехе цель очарована Вами до тех пор, пока она находится в пределах 60 футов от Вас и в течение 1 минуты после этого.`,
    paramIncrease: [
      PARAM_CHA,
    ],
    skillList: SKILL_PERSUASION,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 2,
    },
  },
  {
    id: FEAT_EMPATHIC_2017_04_17,
    name: `Эмпат`,
    nameEn: `Empathic`,
    description: `Вы обладаете глубоким пониманием того, что другие думают и чувствуют. Вы получаете следующие преимущества:

* Увеличьте значение Мудрости на 1 при максимуме 20.
* Вы получаете владение _Проницательностью_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Вы можете действием, попытаться получить сверхъестественное понимание хода мыслей одного гуманоида, которого Вы видите в пределах 30 футов от Вас. Совершите проверку Мудрости (Проницательность), против проверки Харизмы (Обман) цели. При успехе Вы получаете преимущество на броски атаки и проверки способности против цели до конца Вашего следующего хода.`,
    paramIncrease: [
      PARAM_WIT,
    ],
    skillList: SKILL_INSIGHT,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 2,
    },
  },
  {
    id: FEAT_HISTORIAN_2017_04_17,
    name: `Историк`,
    nameEn: `Historian`,
    description: `Ваше знание истории даёт Вам следующие преимущества:

* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение _Историей_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Когда Вы выполняете действие «Помощь», чтобы помочь проверке способности другого существа, Вы можете выполнить проверку Интеллекта (История) СЛ 15. При успехе проверка этого существа получает бонус, равный Вашему бонусу мастерства, поскольку Вы даёте ему уместные советы и исторические примеры. Чтобы получить этот бонус, существо должно понимать, что Вы говорите.
`,
    paramIncrease: [
      PARAM_INT,
    ],
    skillList: SKILL_HISTORY,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 2,
    },
  },
  {
    id: FEAT_INVESTIGATOR_2017_04_17,
    name: `Расследователь`,
    nameEn: `Investigator`,
    description: `Вы внимательны к деталям и замечаете самые мелкие улики. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение _Анализом_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Вы можете совершать Поиск бонусным действием.`,
    paramIncrease: [
      PARAM_INT,
    ],
    skillList: SKILL_INVESTIGATION,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 2,
    },
  },
  {
    id: FEAT_MEDIC_2017_04_17,
    name: `Медик`,
    nameEn: `Medic`,
    description: `Вы овладеваете искусством врачевания, получая следующие преимущества:

* Увеличьте значение Мудрости на 1 при максимуме 20.
* Вы получаете владение _Медициной_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Во время короткого отдыха Вы можете очистить и перевязать раны до шести согласных зверей и гуманоидов. Выполните проверку Мудрости (Медицина) СЛ 15 для каждого существа. При успехе, если существо тратит кость хитов во время этого отдыха, оно может отказаться от броска и вместо этого восстановить максимальное число хитов, которое может восстановить эта кость. Существо может делать так только один раз за отдых, независимо от того, сколько костей оно тратит.`,
    paramIncrease: [
      PARAM_WIT,
    ],
    skillList: SKILL_MEDICINE,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 2,
    },
  },
  {
    id: FEAT_MENACING_2017_04_17,
    name: `Угрожающий`,
    nameEn: `Menacing`,
    description: `Для других Вы выглядите грозным, получая следующие преимущества:

* Увеличьте значение Харизмы на 1 при максимуме 20.
* Вы получаете владение _Запугиванием_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Когда на своём ходу Вы выполняете действие Атака, Вы можете заменить одну из атак попыткой деморализовать одного гуманоида, которого Вы видите в пределах 30 футов, и который может видеть и слышать Вас. Выполните проверку Харизмы (Запугивание) против проверки Мудрости (Проницательность) цели. При успехе цель пугается до конца Вашего следующего хода. При провале Вы больше не можете напугать таким образом эту цель в течение следующего часа.`,
    paramIncrease: [
      PARAM_CHA,
    ],
    skillList: SKILL_INTIMIDATION,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 2,
    },
  },
  {
    id: FEAT_METABOLIC_CONTROL_2020_04_14,
    name: `Управление метаболизмом`,
    nameEn: `Metabolic Control`,
    description: `Вы отточили псионический контроль над функциями своего тела. Вы получаете следующие преимущества:

* Увеличьте значение Силы, Ловкости или Телосложения на 1 при максимуме 20.
* Если Ваша кость Псионических талантов доступна, Вы можете действием пропустить через себя псионические силы, напитывая его энергией на 24 часа, как если бы Вы употребили дневную норму пищи и воды. Использовав эту способность, Вы уменьшаете свою  кость Псионических талантов на один размер. 
* Если Ваша кость Псионических талантов доступна, Вы можете медитировать в течение минуты, в конце которой Вы получаете все преимущества завершённого короткого отдыха. Использовав эту способность, Вы уменьшаете свою  кость Псионических талантов на один размер. Вы не можете снова медитировать таким образом до окончания длинного отдыха.`,
    requirement: [
      {featureId: FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14},
      {feat: FEAT_WILD_TALENT_2020_04_14},
    ],
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
      PARAM_CON,
    ],
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 2,
    },
  },
  {
    id: FEAT_NATURALIST_2017_04_17,
    name: `Натуралист`,
    nameEn: `Naturalist`,
    description: `Ваше глубокое понимание природы даёт Вам следующие преимущества:

* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение _Природой_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Вы изучаете заклинания [Искусство друидов](SPELL:${SPELL_DRUIDCRAFT}) и [Обнаружение болезней и яда](SPELL:${SPELL_DETECT_POISON_AND_DISEASE}). Вы можете сотворить [Обнаружение болезней и яда](SPELL:${SPELL_DETECT_POISON_AND_DISEASE}) один раз без использования ячейки заклинания. Вы восстанавливаете способность сотворять его таким образом, когда завершаете длинный отдых.`,
    paramIncrease: [
      PARAM_INT,
    ],
    skillList: SKILL_NATURE,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 3,
    },
  },
  {
    id: FEAT_PERCEPTIVE_2017_04_17,
    name: `Восприимчивый`,
    nameEn: `Perceptive`,
    description: `Ваши чувства остры как бритва. Вы получаете следующие преимущества:

* Увеличьте значение Мудрости на 1 при максимуме 20.
* Вы получаете владение _Внимательностью_. Если Вы уже ею владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Нахождение в слабо заслонённой области не накладывает помеху на Ваши проверки Мудрости (Восприятие), если Вы можете видеть и слышать.`,
    paramIncrease: [
      PARAM_WIT,
    ],
    skillList: SKILL_PERCEPTION,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 3,
    },
  },
  {
    id: FEAT_PERFORMER_2017_04_17,
    name: `Исполнитель`,
    nameEn: `Performer`,
    description: `Ваши выступления так хороши, что можете управлять любой сценой. Вы получаете следующие преимущества:

* Увеличьте значение Харизмы на 1 при максимуме 20.
* Вы получаете владение _Выступлением_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Во время выступления Вы можете попытаться отвлечь одного гуманоида, которого Вы видите, и который может видеть и слышать Вас. Выполните проверку Харизмы (Выступление) против проверки Мудрости (Проницательность) цели. При успехе, Вы настолько завладеваете вниманием цели, что она получает помехи на все проверки Мудрости (Восприятие) и Интеллекта (Анализ), пока Вы не прекратите выступать.`,
    paramIncrease: [
      PARAM_CHA,
    ],
    skillList: SKILL_PERFORMANCE,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 3,
    },
  },
  {
    id: FEAT_QUICK_FINGERED_2017_04_17,
    name: `Ловкие пальцы`,
    nameEn: `Quick-Fingered`,
    description: `Ваши пальцы ловки и подвижны. Вы получаете следующие преимущества:

* Увеличьте значение Ловкости на 1 при максимуме 20.
* Вы получаете владение _Ловкостью рук_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Бонусным действием Вы можете выполнить проверку Ловкости (Ловкость рук), чтобы поместить что-то на кого-то другого, скрыть предмет на существе, поднять кошелек или вытащить что-то из кармана.`,
    paramIncrease: [
      PARAM_DEX,
    ],
    skillList: SKILL_SLEIGHT_OF_HAND,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 3,
    },
  },
  {
    id: FEAT_SILVER_TONGUED_2017_04_17,
    name: `Сладкоречивый`,
    nameEn: `Silver-Tongued`,
    description: `Вы развили свои разговорные навыки, что позволяет Вам проще обманывать других. Вы получаете следующие преимущества:

* Увеличьте значение Харизмы на 1 при максимуме 20.
* Вы получаете владение _Обманом_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Когда Вы выполняете действие Атака в свой ход, Вы можете заменить одну из атак попыткой обмануть одного гуманоида, которого Вы видите в пределах 30 футов, и который при этом может видеть и слышать Вас. Выполните проверку Харизмы (Обман), против проверки Мудрости (Проницательность) цели. При успехе Ваше движение не провоцирует атаки по возможности от этой цели, и Вы совершаете броски атаки против неё с преимуществом; оба эффекта действуют до конца Вашего следующего хода или до тех пор, пока Вы не используете эту способность на другую цель. При провале Вы больше не сможете обмануть таким образом эту цель в течение следующего часа.`,
    paramIncrease: [
      PARAM_CHA,
    ],
    skillList: SKILL_DECEPTION,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 3,
    },
  },
  {
    id: FEAT_STEALTHY_2017_04_17,
    name: `Незаметный`,
    nameEn: `Stealthy`,
    description: `Вы знаете, как нужно прятаться. Вы получаете следующие преимущества:

* Увеличьте значение Ловкости на 1 при максимуме 20.
* Вы получаете владение _Скрытностью_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Если Вы скрыты, то можете передвигаться на расстояние до 10 футов через открытое пространство, не раскрывая себя, если заканчиваете передвижение в позиции, где Вас плохо видно.`,
    paramIncrease: [
      PARAM_DEX,
    ],
    skillList: SKILL_STEALTH,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 4,
    },
  },
  {
    id: FEAT_SURVIVALIST_2017_04_17,
    name: `Выживальщик`,
    nameEn: `Survivalist`,
    description: `Вы овладеваете знаниями дикой природы, получая следующие преимущества:

* Увеличьте значение Мудрости на 1 при максимуме 20.
* Вы получаете владение _Выживанием_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Вы изучаете заклинание [Тревога](SPELL:${SPELL_ALARM}). Вы можете сотворить его один раз без использования ячейки заклинания. Вы восстанавливаете способность сотворять его таким образом, когда завершаете длинный отдых.`,
    paramIncrease: [
      PARAM_WIT,
    ],
    skillList: SKILL_SURVIVAL,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 4,
    },
  },
  {
    id: FEAT_THEOLOGIAN_2017_04_17,
    name: `Теолог`,
    nameEn: `Theologian`,
    description: `Ваше обширные познания в религии дают Вам следующие преимущества:

* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение _Религией_. Если Вы уже ей владеете, то добавляете двойной бонус мастерства ко всем её проверкам.
* Вы изучаете заклинания [Чудотворство](SPELL:${SPELL_THAUMATURGY}) и [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD}). Вы можете сотворить [Обнаружение добра и зла](SPELL:${SPELL_DETECT_EVIL_AND_GOOD}) один раз без использования ячейки заклинания. Вы восстанавливаете способность сотворять его таким образом, когда завершаете длинный отдых.`,
    paramIncrease: [
      PARAM_INT,
    ],
    skillList: SKILL_RELIGION,
    source: {
      id: SOURCE_UA_2017_04_17_FFS,
      page: 4,
    },
  },
  {
    id: FEAT_BARBED_HIDE_2017_04_24,
    name: `Шипастая шкура`,
    nameEn: `Barbed Hide`,
    description: `Один из Ваших предков был [Шипастым дьяволом](CREATURE:${CREATURE_BARBED_DEVIL}) или другим шипастым исчадием. Шипы выступают из Вашей головы. Вы получаете следующие преимущества:

* Увеличьте значение Телосложения или Харизмы на 1 при максимуме 20.
* Вы можете бонусным действием выпустить или втянуть маленькие шипы по всему телу. В начале каждого Вашего хода, пока шипы выпущены, Вы наносите 1к6 колющего урона любому существу, схватившему Вас или схваченному Вами.
* Вы получаете владение _Запугиванием_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.`,
    requirement: {
      race: PC_RACE_TIEFLING,
    },
    paramIncrease: [
      PARAM_CON,
      PARAM_CHA,
    ],
    skillList: SKILL_INTIMIDATION,
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 1,
    },
  },
  {
    id: FEAT_CRITTER_FRIEND_2017_04_24,
    name: `Друг зверюшек`,
    nameEn: `Critter Friend`,
    description: `Ваша дружба с животными мистически углубляется. Вы получаете следующие преимущества:

* Вы получаете владение _Обращением с животными_. Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Вы изучаете заклинание [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}) и можете сотворять не затрачивая ячейки заклинаний. Также Вы изучаете заклинание [Дружба с животными](SPELL:${SPELL_ANIMAL_FRIENDSHIP}) и можете разыграть его один раз без использования ячейки заклинания. Вы восстанавливаете способность сотворять его таким образом, когда завершаете длинный отдых. Интеллект — Ваша заклинательная характеристика для этих заклинаний.`,
    requirement: {
      race: PC_RACE_GNOME,
      subrace: PC_SUBRACE_GNOME_FOREST,
    },
    skillList: SKILL_ANIMAL_HANDLING,
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 1,
    },
  },
  {
    id: FEAT_DRAGON_WINGS_2017_04_24,
    name: `Драконьи крылья`,
    nameEn: `Dragon Wings`,
    description: `Вы отращиваете драконьи крылья. С ними Вы получаете скорость полёта 20 футов, если не носите тяжелую броню и не превышаете свою грузоподъемность.`,
    requirement: {
      race: PC_RACE_DRAGONBORN,
    },
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 2,
    },
  },
  {
    id: FEAT_EVERYBODYS_FRIEND_2017_04_24,
    name: `Друг для всех`,
    nameEn: `Everybody’s Friend`,
    description: `Ваша индивидуальность крайне притягательна, что заметно облегчает Ваш путь по этому миру. Вы получаете следующие преимущества:

* Увеличьте значение Харизмы на 1 при максимуме 20.
* Вы получаете владение _Убеждением_ и _Обманом_. Если Вы уже ими владеете, то добавляете двойной бонус мастерства ко всем их проверкам.`,
    requirement: {
      race: PC_RACE_HALF_ELF,
    },
    paramIncrease: [
      PARAM_CHA,
    ],
    skillList: [
      SKILL_DECEPTION,
      SKILL_PERSUASION,
    ],
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 2,
    },
  },
  {
    id: FEAT_GRUDGE_BEARER_2017_04_24,
    name: `Злопамятный`,
    nameEn: `Grudge-Bearer`,
    description: `Вы глубоко ненавидите определённый вид существ. Выберите своих врагов — тип существ, которые будет нести бремя Вашего гнева: аберрации, звери, небожители, конструкты, драконы, элементали, феи, исчадия, великаны, монстры, слизи, растения или нежить. Или же, вместо этого, Вы можете выбрать две расы гуманоидов (таких как [гноллы](CREATURE:${CREATURE_GNOLL}) и [орки](CREATURE:${CREATURE_ORC})). Вы получаете следующие преимущества:

* Увеличьте значение Силы, Телосложения или Мудрости на 1 при максимуме 20.
* Во время первого раунда любого боя с выбранными Вами противниками Вы совершаете с преимуществом броски атаки против них.
* Когда любой из выбранных Вами противников совершает по Вам провоцированную атаку, он получает помеху к броску атаки.
* Каждый раз, когда Вы совершаете проверку Интеллекта (Магии, Истории, Природы или Религии), чтобы вспомнить информацию о выбранных Вами противниках, Вы добавляете к проверке двойной бонус мастерства, даже если не владеете этим навыком.`,
    requirement: {
      race: PC_RACE_DWARF,
    },
    paramIncrease: [
      PARAM_STR,
      PARAM_CON,
      PARAM_WIT,
    ],
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 3,
    },
  },
  {
    id: FEAT_HUMAN_DETERMINATION_2017_04_24,
    name: `Человеческая решительность`,
    nameEn: `Human Determination`,
    description: `Вы полны решительности и можете совершать почти невозможное. Вы получаете следующие преимущества:

* Увеличьте значение любой характеристики на 1, при максимуме 20.
* Когда Вы совершаете бросок атаки, проверку способности или проходите испытание, Вы можете сделать это с преимуществом. Если Вы используете эту способность, то не сможете использовать её снова, пока не закончите короткий или длинный отдых.`,
    requirement: {
      race: PC_RACE_HUMAN,
    },
    paramIncrease: [
      PARAM_ANY,
    ],
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 3,
    },
  },
  {
    id: FEAT_ORCISH_AGGRESSION_2017_04_24,
    name: `Орочья агрессия`,
    nameEn: `Orcish Aggression`,
    description: `Вы можете бонусным действием переместиться на расстояние, не превышающее Вашу скорость, в сторону видимого или слышимого Вами враждебного существа. Вы должны закончить это передвижение ближе к этому существу, чем начали.`,
    requirement: {
      race: PC_RACE_HALF_ORC,
    },
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 3,
    },
  },
  {
    id: FEAT_WONDER_MAKER_2017_04_24,
    name: `Чудодей`,
    nameEn: `Wonder Maker`,
    description: `Вы овладеваете изобретательскими навыками своего народа и получаете следующие преимущества:

* Увеличьте значение Ловкости или Интеллекта на 1 при максимуме 20.
* Когда Вы совершаете проверку, используя владение [инструментами ремонтника](GEAR:${GEAR_TINKERS_TOOLS}), то добавляете к этой проверке двойной бонус мастерства.
* Когда Вы создаете устройство с помощью Вашей расовой особенности _Жестянщик_, у Вас есть следующие дополнительные варианты устройств:

* **Сигнализация.** Это устройство распознает, когда существо проходит в пределах 15 футов от него, не произнеся вслух пароль, выбранный при его создании. Через один раунд после того, как существо входит в зону действия, сигнализация издаёт пронзительный звон, который длится 1 минуту и ​​может быть слышен на расстоянии до 300 футов.
* **Калькулятор.** Это устройство позволяет легко складывать числа.
* **Подъёмник.** Это устройство может использоваться как [Блок и лебёдка](GEAR:${GEAR_BLOCK_AND_TACKLE}), позволяя поднимать груз в пять раз тяжелее обычного.
* **Хронометр.** Эти карманные часы показывают точное время.
* **Датчик погоды.** Если использовать его действием, то это устройство прогнозирует погодные условия в радиусе 1 мили в течение следующих 4 часов, показывая один из символов (облака, солнце / луна, дождь, снег) на каждый час.`,
    requirement: {
      race: PC_RACE_GNOME,
      subrace: PC_SUBRACE_GNOME_ROCK,
    },
    paramIncrease: [
      PARAM_DEX,
      PARAM_INT,
    ],
    instrumentList: INSTRUMENT_TINKERS_TOOLS,
    source: {
      id: SOURCE_UA_2017_04_24_FFR,
      page: 4,
    },
  },
  {
    id: FEAT_REVENANT_BLADE,
    name: `Мстительный клинок`,
    nameEn: `Revenant Blade`,
    description: `Вы — потомок мастера боя на [двуклинковых скимитарах](GEAR:${GEAR_DOUBLE_BLADED_SCIMITAR}) и часть его мастерства передалась и Вам. Вы получаете следующие преимущества:

* Увеличьте значение Силы или Ловкости на 1, при максимуме 20.
* Вы получаете бонус +1 к КД, когда держите двуклинковый скимитар двумя руками.
* Для Вас двуклинковые скимитары считаются фехтовальным оружием.`,
    requirement: {
      race: PC_RACE_ELF,
    },
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: {
      id: SOURCE_ERFTLW,
      page: 4,
    },
  },
  {
    id: FEAT_SVIRFNEBLIN_MAGIC,
    name: `Магия свирфнеблинов`,
    nameEn: `Svirfneblin Magic`,
    description: `Вы унаследовали врождённую способность к магии от своих предков.

Она позволяет Вам сотворять на себя заклинание [Необнаружимость](SPELL:${SPELL_NONDETECTION}) по желанию и без надобности использовать материальные компоненты. Вы также можете однократно сотворить следующие заклинания: [Слепота/Глухота](SPELL:${SPELL_BLINDNESS_DEAFNESS}), [Размытый образ](SPELL:${SPELL_BLUR}) и [Маскировка](SPELL:${SPELL_DISGUISE_SELF}).

Вы восстанавливаете возможность творить эти заклинания совершив длинный отдых. Интеллект является ключевой характеристикой для сотворения всех этих заклинаний.`,
    requirement: {
      race: PC_RACE_GNOME,
      subrace: PC_SUBRACE_GNOME_DEEP,
    },
    source: {
      id: SOURCE_SCAG,
      page: 116,
    },
  },
  {
    id: FEAT_TELEKINETIC_2019_11_25,
    releasedAs: FEAT_TELEKINETIC,
    name: `Телекинет`,
    nameEn: `Telekinetic`,
    description: `Вы научились двигать вещи силой своего разума. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы изучаете заговор [Волшебная рука](SPELL:${SPELL_MAGE_HAND}) и можете сотворять его, не нуждаясь в вербальных и соматических компонентах. Вы можете делать призрачную руку невидимой.
* Бонусным действием Вы можете попытаться толкнуть одно существо, видимое Вами в пределах 5 футов от призрачной руки, созданной Вашим заговором [Волшебная рука](SPELL:${SPELL_MAGE_HAND}). Когда Вы это делаете, цель должна пройти испытание Силы (СЛ 8 + Ваш бонус мастерства + Ваш модификатор Интеллекта) или рука оттолкнёт её на 5 футов от Вас.`,
    paramIncrease: [
      PARAM_INT,
    ],
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 8,
    },
  },
  {
    id: FEAT_TELEKINETIC_2020_04_14,
    releasedAs: FEAT_TELEKINETIC,
    name: `Телекинет`,
    nameEn: `Telekinetic`,
    description: `Вы научились двигать вещи силой своего разума. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы изучаете заговор [Волшебная рука](SPELL:${SPELL_MAGE_HAND}) и можете сотворять его, не нуждаясь в вербальных и соматических компонентах. Вы можете делать призрачную руку невидимой. Если Вы уже знаете это заклинание, его дальность увеличивается на 30 футов. Его заклинательная характеристика — это та характеристика, которую Вы увеличили этой чертой.
* Бонусным действием Вы можете попытаться телекинетически толкнуть одно существо, видимое Вами в пределах 5 футов от призрачной руки, созданной Вашим заговором [Волшебная рука](SPELL:${SPELL_MAGE_HAND}). Когда Вы это делаете, совершите бросок своей кости Псионических талантов. Цель должна пройти испытание Силы (СЛ 8 + Ваш бонус мастерства + Ваш модификатор характеристики, которую Вы увеличили этой чертой) или рука оттолкнёт её к Вам или от Вас на расстояние 5 футов, умноженное на результат броска Вашей кости Псионических талантов.`,
    requirement: [
      {featureId: FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14},
      {feat: FEAT_WILD_TALENT_2020_04_14},
    ],
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 8,
    },
  },
  {
    id: FEAT_TELEKINETIC,
    name: `Телекинет`,
    nameEn: `Telekinetic`,
    description: `Вы научились двигать вещи силой своего разума. Вы получаете следующие преимущества:

* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы изучаете заговор [Волшебная рука](SPELL:${SPELL_MAGE_HAND}) и можете сотворять его, не нуждаясь в вербальных и соматических компонентах. Вы можете делать призрачную руку невидимой. Если Вы уже знаете это заклинание, его дальность увеличивается на 30 футов. Его заклинательная характеристика — это та характеристика, которую Вы увеличили этой чертой.
* Бонусным действием Вы можете попытаться телекинетически толкнуть одно существо, видимое Вами в пределах 30 футов. Когда Вы это делаете, цель должна пройти испытание Силы (СЛ 8 + Ваш бонус мастерства + Ваш модификатор характеристики, которую Вы увеличили этой чертой) или рука оттолкнёт её к Вам или от Вас на расстояние 5 футов. Существо может добровольно провалить испытание.`,
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 122,
    },
  },
  {
    id: FEAT_TELEPATHIC_2019_11_25,
    releasedAs: FEAT_TELEPATHIC,
    name: `Телепат`,
    nameEn: `Telepathic`,
    description: `Вы пробудили в себе способности мысленно общаться с другими. Вы получаете следующие преимущества:
    
* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение одним навыком по Вашему выбору: _Убеждение_, _Обман_, _Проницательность_ или _Запугивание_.
* Вы можете телепатически общаться с любым видимым Вами в пределах 30 футов существом. Если оно понимает хотя бы один язык, то может телепатически Вам отвечать.`,
    paramIncrease: [
      PARAM_INT,
    ],
    skillList: [
      SKILL_DECEPTION,
      SKILL_INSIGHT,
      SKILL_INTIMIDATION,
      SKILL_PERSUASION,
    ],
    source: {
      id: SOURCE_UA_2019_11_25_PSYCHIC_SOUL_PSIONICS,
      page: 83,
    },
  },
  {
    id: FEAT_TELEPATHIC_2020_04_14,
    releasedAs: FEAT_TELEPATHIC,
    name: `Телепат`,
    nameEn: `Telepathic`,
    description: `Вы пробудили в себе способности мысленно общаться с другими. Вы получаете следующие преимущества:
    
* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы можете телепатически говорить с любым видимым Вами в пределах 30 футов существом. Вы телепатически произносите слова на известном Вам языке и существо понимает Вас только если оно понимает этот язык. Ваше общение не позволяет существу телепатически Вам отвечать.
* Если Ваша кость Псионических талантов доступна, Вы можете сотворить заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) без каких-либо компонентов. Его заклинательная характеристика — это та характеристика, которую Вы увеличили этой чертой. Использовав эту способность, Вы уменьшаете свою кость Псионических талантов на один размер.`,
    requirement: [
      {featureId: FEATURE_PSIONIC_TALENT_ROGUE_SOUL_KNIFE_2020_04_14},
      {feat: FEAT_WILD_TALENT_2020_04_14},
    ],
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_UA_2020_04_14_PSIONIC_OPTIONS_REVISITED,
      page: 8,
    },
  },
  {
    id: FEAT_TELEPATHIC,
    name: `Телепат`,
    nameEn: `Telepathic`,
    description: `Вы пробудили в себе способности мысленно общаться с другими. Вы получаете следующие преимущества:
    
* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы можете телепатически говорить с любым видимым Вами в пределах 60 футов существом. Вы телепатически произносите слова на известном Вам языке и существо понимает Вас только если оно понимает этот язык. Ваше общение не позволяет существу телепатически Вам отвечать.
* Вы можете сотворять заклинание [Обнаружение мыслей](SPELL:${SPELL_DETECT_THOUGHTS}) без траты ячеек заклинаний и компонентов. Вы восстанавливаете способность сотворять его таким образом, когда завершаете длинный отдых. Его заклинательная характеристика — это та характеристика, которую Вы увеличили этой чертой. Также Вы можете сотворять это заклинание, тратя свои ячейки заклинаний 2 круга и выше.`,
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 122,
    },
  },
  {
    id: FEAT_FELL_HANDED_2016_06_06,
    name: `Направленный удар`,
    nameEn: `Fell Handed`,
    description: `Вы совершенствуетесь во владении [топориками](GEAR:${GEAR_HANDAXE}), [боевыми топорами](GEAR:${GEAR_BATTLEAXE}), [двуручными топорами](GEAR:${GEAR_GREATAXE}), [боевыми молотами](GEAR:${GEAR_WARHAMMER}) и [двуручными молотами](GEAR:${GEAR_MAUL}). Вы получаете следующие преимущества, когда используете одно из этих оружий.

* Вы получаете бонус +1 к атакам оружием.
* Если Вы атакуете рукопашной атакой оружием с преимуществом и попадаете, то Вы можете сбить цель с ног, если результата броска второго к20 тоже достаточно для попадания.
* Если Вы атакуете рукопашной атакой оружием с помехой и промахиваетесь, то цель получает дробящий, равный Вашему модификатору Силы (минимум 0), если результата броска второго к20 достаточно для попадания.
* Если Вы, держа оружие, используете действие Помощь, чтобы дать преимущество к рукопашной атаке союзника, Вы ненадолго отбрасываете [щит](GEAR:${GEAR_SHIELD}) цели. Кроме получения преимущества, Ваш собзник получит +2 к атаке, если цель использует [щит](GEAR:${GEAR_SHIELD}).`,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 2,
    },
  },
  {
    id: FEAT_BLADE_MASTERY_2016_06_06,
    name: `Мастер клинков`,
    nameEn: `Blade Mastery`,
    description: `Вы совершенствуетесь во владении [короткими мечами](GEAR:${GEAR_SHORTSWORD}), [длинными мечами](GEAR:${GEAR_LONGSWORD}), [скимитарами](GEAR:${GEAR_SCIMITAR}), [рапирами](GEAR:${GEAR_RAPIER}) и [двуручными мечами](GEAR:${GEAR_GREATSWORD}). Вы получаете следующие преимущества, когда используете одно из этих оружий.

* Вы получаете бонус +1 к атакам оружием.
* На своём ходу Вы можете реакцией принять парирующую стойку, если оружие у Вас в руке. Это даст Вам +1 к КД до начала Вашего следующего хода, пока Вы держите оружие.
* Вы получаете преимущество к провоцированным атакам оружием.`,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 2,
    },
  },
  {
    id: FEAT_FLAIL_MASTERY_2016_06_06,
    name: `Мастер цепов`,
    nameEn: `Flail Mastery`,
    description: `[Цеп](GEAR:${GEAR_FLAIL}) — сложное в использовании оружие, но Вы провели бессчётные часы, совершенствуя своё владение им. Вы получаете следующие преимущества.

* Вы получаете бонус +1 к атакам [цепом](GEAR:${GEAR_FLAIL}).
* Бонусным действием в свой ход Вы можете приготовиться к тому, чтобы закидывать било Вашего [цепа](GEAR:${GEAR_FLAIL}) за [щиты](GEAR:${GEAR_SHIELD}) врагов. До конца этого хода Вы получаете +2 ко всем броскам атаки цепом против врагов со [щитами](GEAR:${GEAR_SHIELD}).
* Когда Вы попадаете провоцированную атаку [цепом](GEAR:${GEAR_FLAIL}), цель должна пройти испытание Силы (СЛ 8 + Ваш бонус мастерства + Ваш модификатор Силы) или упасть ничком.`,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_SPEAR_MASTERY_2016_06_06,
    name: `Мастер копий`,
    nameEn: `Spear Mastery`,
    description: `Хотя обучиться владению [копьём](GEAR:${GEAR_SPEAR}) и несложно, оно становится намного эффективнее в руках того, кто посвятил много времени тренировкам с ним. Вы получаете следующие преимущества.

* Вы получаете бонус +1 к атакам [копьём](GEAR:${GEAR_SPEAR}).
* Когда Вы используете [копьё](GEAR:${GEAR_SPEAR}), его кость урона увеличивается с к6 до к8 и с к8 до к10 при ударе двумя руками (этот преимущество не действует, если какие-либо другие эффекты уже увеличили кость урона оружия).
* Вы можете выставить [копьё](GEAR:${GEAR_SPEAR}) для атаки. Бонусным действием выберите существо, видимое Вами в пределах 20 футов. Если это существо войдёт в пределы досягаемости Вашего [копья](GEAR:${GEAR_SPEAR}) на своём следующем ходу, Вы можете реакцией совершить по нему рукопашную атаку [копьём](GEAR:${GEAR_SPEAR}). При попадании цель получает дополнительно 1к8 (или 1к10 при атаке двумя руками) колющего урона. Вы не можете использовать эту способность, если цель совершила действие Отход перед передвижением.
* Бонусным действие на своём ходу Вы можете увеличить досягаемость своего [копья](GEAR:${GEAR_SPEAR}) на 5 футов до конца хода.`,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_ALCHEMIST_2016_06_06,
    name: `Алхимик`,
    nameEn: `Alchemist`,
    description: `Вы изучили секреты алхимии и стали экспертом в этом ремесле. Вы получаете следующие преимущества:
    
* Увеличьте значение Интеллекта на 1 при максимуме 20.
* Вы получаете владение [инструментами алхимика](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}). Если Вы уже ими владеете, то добавляете двойной бонус мастерства ко всем их проверкам.
* Вы можете действием опознать одно зелье в пределах 5 футов от Вас, как будто бы Вы его попробовали. Чтобы это преимущество действовало, Вы должны видеть жидкость.
* За время короткого отдыха Вы можете временно улучшить одно зелье лечения любого качества. Чтобы это преимущество действовало, Вы должны иметь с собой [инструменты алхимика](GEAR:${GEAR_ALCHEMIST_S_SUPPLIES}), а зелье должно быть в пределах Вашей досягаемости. Если зелье выпить не позже чем через 1 час после окончания этого короткого отдыха, пьющее его существо может не бросать кости для восстановления хитов, а восстановить максимум хитов, который может востанавливать это зелье.`,
    paramIncrease: [
      PARAM_INT,
    ],
    instrumentList: INSTRUMENT_ALCHEMISTS_SUPPLIES,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 4,
    },
  },
  {
    id: FEAT_BURGLAR_2016_06_06,
    name: `Взломщик`,
    nameEn: `Burglar`,
    description: `Вы гордитесь своим проворством и близким знакомством с некоторыми подпольными делами. Вы получаете следующие преимущества:
    
* Увеличьте значение Ловкости на 1 при максимуме 20.
* Вы получаете владение [воровскими инструментами](GEAR:${GEAR_THIEVES_TOOLS}). Если Вы уже ими владеете, то добавляете двойной бонус мастерства ко всем их проверкам.`,
    paramIncrease: [
      PARAM_DEX,
    ],
    skillList: INSTRUMENT_THIEVES_TOOLS,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 4,
    },
  },
  {
    id: FEAT_MASTER_OF_DISGUISE_2016_06_06,
    name: `Мастер маскировки`,
    nameEn: `Master of Disguise`,
    description: `Вы отточили способности менять внешность подражать внешности других. Вы получаете следующие преимущества:
    
* Увеличьте значение Харизмы на 1 при максимуме 20.
* Вы получаете владение [набором для грима](GEAR:${GEAR_DISGUISE_KIT}). Если Вы уже им владеете, то добавляете двойной бонус мастерства ко всем его проверкам.
* Если Вы проведёте 1 час, наблюдая за существом, Вы позже можете провесит 8 часов, изготавливая личину, которую Вы сможете быстро надеть, чтобы подражать этому существу. Для создания личины требуется [набор для грима](GEAR:${GEAR_DISGUISE_KIT}). Вы должны будете делать проверки маскирования как обычно, но сможете замаскироваться действием.`,
    paramIncrease: [
      PARAM_CHA,
    ],
    instrumentList: INSTRUMENT_DISGUISE_KIT,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 4,
    },
  },
  {
    id: FEAT_GOURMAND_2016_06_06,
    name: `Гурман`,
    nameEn: `Gourmand`,
    description: `Вы узнали множество особых рецептов, что позволяет Вам готовить изысканнейшие блюда с полезными эффектами. Вы получаете следующие преимущества:
    
* Увеличьте значение Телосложения на 1 при максимуме 20.
* Вы получаете владение [инструментами повара](GEAR:${GEAR_COOK_S_UTENSILS}). Если Вы уже ими владеете, то добавляете двойной бонус мастерства ко всем их проверкам.
* Вы можете действием проверить на наличие яда одно блюдо или напиток в пределах 5 футов от Вас. Чтобы это преимущество действовало, Вы должны видеть и обонять это блюдо или напиток.
* За время длинного отдыха Вы можете приготовить и накрыть обед, который позволит Вам и Вашим союзникам восстановиться после тягот приключений. Чтобы это преимущество действовало, Вы должны иметь с собой [инструменты повара](GEAR:${GEAR_COOK_S_UTENSILS}), подходящие продукты и другие припасы. Приготовленной пищи достаточно для шести персонажей. Каждый отведавший Вашей стряпни персонаж восстановит две дополнительные кости хитов после окончания этого длинного отдыха. Кроме того, он получит преимущество к испытаниям Телосложения на следующие 24 часа.`,
    paramIncrease: [
      PARAM_CON,
    ],
    instrumentList: INSTRUMENT_COOKS_UTENSILS,
    source: {
      id: SOURCE_UA_2016_06_06_FEATS,
      page: 4,
    },
  },
  {
    id: FEAT_ARTIFICER_INITIATE,
    name: `Начинающий изобретатель`,
    nameAlt: `Посвящённый в изобретательство`,
    nameEn: `Artificer Initiate`,
    description: `Вы научились находчивости изобретателей.

* Вы узнаёте один заговор и одно заклинание 1 круга из списка заклинаний [изобретателя](PC_CLASS:${PC_CLASS_ARTIFICER}). Базовая характеристика для этих заклинаний: Интеллект.
* Вы можете сотворять выбранное заклинание 1 круга, не тратя ячейку заклинания, и должны закончить длинный отдых, прежде чем сможете снова сотворить его таким образом. Также Вы можете сотворять это заклинание, тратя свои ячейки заклинаний.
* Вы получаете владение одним набором ремесленных инструментов по Вашему выбору и можете использовать их как свою заклинательную фокусировку для любого заклинания, которое Вы сотворяете, используя Интеллект как заклинательную способность.`,
    instrumentList: INSTRUMENT_ANY,
    source: {
      id: SOURCE_TCoE,
      page: 119,
    },
  },
  {
    id: FEAT_ARTIFICER_INITIATE_2020_07_13,
    releasedAs: FEAT_ARTIFICER_INITIATE,
    name: `Начинающий изобретатель`,
    nameAlt: `Посвящённый в изобретательство`,
    nameEn: `Artificer Initiate`,
    description: `Вы научились находчивости изобретателей, получая следующие преимущества:

* Вы узнаёте один заговор и одно заклинание 1 круга из списка заклинаний [изобретателя](PC_CLASS:${PC_CLASS_ARTIFICER}). Базовая характеристика для этих заклинаний: Интеллект. Когда Вы получаете уровень, Вы можете заменить любое из этих заклинаний на другое того же круга из списка заклинаний [изобретателя](PC_CLASS:${PC_CLASS_ARTIFICER}).
* Вы можете сотворять выбранное заклинание 1 круга, не тратя ячейку заклинания, и должны закончить длинный отдых, прежде чем сможете снова сотворить его таким образом. Также Вы можете сотворять это заклинание, тратя свои ячейки заклинаний.
* Вы получаете владение одним набором ремесленных инструментов по Вашему выбору и можете использовать их как свою заклинательную фокусировку для любого заклинания, которое Вы сотворяете, используя Интеллект как заклинательную способность.`,
    instrumentList: INSTRUMENT_ANY,
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 1,
    },
  },
  {
    id: FEAT_CHEF,
    name: `Шеф-повар`,
    nameEn: `Chef`,
    description: `Время и усилия, затраченные на совершенствование Вашего кулинарного мастерства, приносят свои плоды. Вы получаете следующие преимущества:
    
* Увеличьте значение Телосложения или Мудрости на 1 при максимуме 20.
* Вы получаете владение [инструментами повара](GEAR:${GEAR_COOK_S_UTENSILS}), если ещё не владели ими.
* Как часть короткого отдыха Вы можете приготовить особое блюдо, если у Вас под рукой есть ингридиенты и [инструменты повара](GEAR:${GEAR_COOK_S_UTENSILS}). Приготовленной пищи достаточно, чтобы накормить 4 + Ваш бонус мастерства существ. В конце короткого отдыха каждое отведавшее Вашей стряпни существо, которое потратило кости хитов для восстановления хитов, восстанавливает дополнительно 1к8 хитов.
* Если Вы потратите один час или в конце длинного отдыха Вы можете приготовить сладости, в количестве, равном Вашему бонусу мастерства. Эти особые сладости можно есть в течение 8 часов после приготовления. Существо может съесть такую сладость бонусным действием и получить временные хиты, равные Вашему бонусу мастерства.`,
    paramIncrease: [
      PARAM_CON,
      PARAM_WIT,
    ],
    instrumentList: INSTRUMENT_COOKS_UTENSILS,
    source: [
      {
        id: SOURCE_TCoE,
        page: 119,
      },
      {
        id: SOURCE_UA_2020_07_13_FEATS,
        page: 1,
      },
    ],
  },
  {
    id: FEAT_CRUSHER,
    name: `Крушила`,
    nameEn: `Crusher`,
    description: `Вы научились искусству сокрушения врагов, получая следующие преимущества:
    
* Увеличьте значение Силы или Телосложения на 1 при максимуме 20.
* Один раз в ход, когда Вы попадаете по существу атакой, наносящей дробящий урон, Вы можете передвинуть его на 5 футов в свободное пространство, если цель не больше чем на один размер больше Вас.
* Когда Вы попадаете по существу критическим ударом, наносящим дробящий урон, все броски атаки по этому существу совершаются с преимуществом до конца Вашего следующего хода.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_CON,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 119,
    },
  },
  {
    id: FEAT_CRUSHER_2020_07_13,
    releasedAs: FEAT_CRUSHER,
    name: `Крушила`,
    nameEn: `Crusher`,
    description: `Вы научились искусству сокрушения врагов, получая следующие преимущества:
    
* Увеличьте значение Силы или Ловкости на 1 при максимуме 20.
* Один раз в ход, когда Вы попадаете по существу атакой, наносящей дробящий урон, Вы можете передвинуть его на 5 футов в свободное пространство, если цель не больше чем на один размер больше Вас.
* Когда Вы попадаете по существу критическим ударом, наносящим дробящий урон, все броски атаки по этому существу совершаются с преимуществом до конца Вашего следующего хода.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 1,
    },
  },
  {
    id: FEAT_ELDRITCH_ADEPT,
    name: `Таинственный адепт`,
    nameEn: `Eldritch Adept`,
    requirement: [
      {text: `Умение «Использование заклинаний»`},
      {featureId: FEATURE_PACT_MAGIC},
    ],
    description: `Изучая тёмные искусства, Вы открыли внутри себя таинственную силу.

Вы изучаете одно [Таинственное воззвание](/invocation-catalog/) из класса [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}). Если воззвание имеет требования, то Вы можете выбрать его только если Вы — [колдун](PC_CLASS:${PC_CLASS_WARLOCK}) и соответствуете этим требованиям.

Когда Вы получаете уровень, Вы можете заменить выбранное воззвание другим из [списка воззваний](/invocation-catalog/) [колдуна](PC_CLASS:${PC_CLASS_WARLOCK}).`,
    source: [
      {
        id: SOURCE_TCoE,
        page: 119,
      },
      {
        id: SOURCE_UA_2020_07_13_FEATS,
        page: 1,
      },
    ],
  },
  {
    id: FEAT_FEY_TOUCHED,
    name: `Отмеченный феями`,
    nameEn: `Fey Touched`,
    description: `Ваш контакт со Страной Фей или одним из её обитателей оставил на Вас магический след.

* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы изучаете заклинание [Туманный шаг](SPELL:${SPELL_MISTY_STEP}) и одно заклинание 1 круга по Вашему выбору. Выбранное заклинание 1 круга должно принадлежать школам прорицания или очарования. Вы можете сотворять эти заклинания без траты ячейки заклинания. Сотворив так одно из этих заклинаний, Вы не можете сотворять его снова, пока не закончите длинный отдых. Также Вы можете сотворять эти заклинания, тратя свои ячейки заклинаний. Базовая характеристика для этих заклинаний это характеристика, увеличенная этой чертой.`,
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: [
      {
        id: SOURCE_TCoE,
        page: 120,
      },
      {
        id: SOURCE_UA_2020_07_13_FEATS,
        page: 2,
      },
    ],
  },
  {
    id: FEAT_FIGHTING_INITIATE,
    name: `Начинающий воин`,
    nameEn: `Fighting Initiate`,
    requirement: `Владение любым воинским оружием`,
    description: `Упорные тренировки помогли Вам выработать определённый стиль боя. Вы осваиваете один боевой стиль, доступный классу [воина](PC_CLASS:${PC_CLASS_FIGHTER}), которым Вы ещё не владеете.

Когда Вы получаете уровень, на котором Вы получаете способность «Увеличение характеристик», Вы можете заменить полученный этой чертой боевой стиль на другой боевой стиль [воина](PC_CLASS:${PC_CLASS_FIGHTER}), которым Вы ещё не владеете.`,
    source: {
      id: SOURCE_TCoE,
      page: 120,
    },
  },
  {
    id: FEAT_FIGHTING_INITIATE_2020_07_13,
    releasedAs: FEAT_FIGHTING_INITIATE,
    name: `Начинающий воин`,
    nameEn: `Fighting Initiate`,
    requirement: `Владение любым воинским оружием`,
    description: `Упорные тренировки помогли Вам выработать определённый стиль боя. Вы осваиваете один боевой стиль, доступный классу [воина](PC_CLASS:${PC_CLASS_FIGHTER}), которым Вы ещё не владеете.

Когда Вы получаете уровень, Вы можете заменить полученный этой чертой боевой стиль на другой боевой стиль [воина](PC_CLASS:${PC_CLASS_FIGHTER}), которым Вы ещё не владеете.`,
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 2,
    },
  },
  {
    id: FEAT_GUNNER,
    name: `Стрелок`,
    nameEn: `Gunner`,
    description: `Ваша рука быстра, а глаз меток, когда дело доходит до огнестрела. Это даёт Вам следующие преимущества:
    
* Увеличьте Ловкости на 1 при максимуме 20.
* Вы получаете владение огнестрелом (см. «Огнестрел» в Руководстве Мастера»).
* Вы игнорируете свойство _«перезарядка»_ у огнестрела.
* Нахождение в пределах 5 футов от враждебного существа не накладывает помеху на Ваши броски дальнобойных атак.`,
    source: [
      {
        id: SOURCE_TCoE,
        page: 120,
      },
      {
        id: SOURCE_UA_2020_07_13_FEATS,
        page: 2,
      },
    ],
  },
  {
    id: FEAT_METAMAGIC_ADEPT,
    name: `Адепт метамагии`,
    nameEn: `Metamagic Adept`,
    requirement: [
      {text: `Умение «Использование заклинаний»`},
      {featureId: FEATURE_PACT_MAGIC},
    ],
    description: `Вы научились изменять природу своих заклинаний одной лишь силой воли. Это даёт Вам следующие преимущества:

* Вы осваиваете два варианта [Метамагии](FEATURE:${FEATURE_METAMAGIC}) из доступных классу [чародея](PC_CLASS:${PC_CLASS_SORCERER}). Вы можете применить только один вариант [Метамагии](FEATURE:${FEATURE_METAMAGIC}) на сотворяемое заклинание, если только выбраный вариант не разрешает иного. Когда Вы получаете уровень, на котором Вы получаете способность «Увеличение характеристик», Вы можете заменить полученный этой чертой вариант [Метамагии](FEATURE:${FEATURE_METAMAGIC}) на другой вариант [Метамагии](FEATURE:${FEATURE_METAMAGIC}) [чародея](PC_CLASS:${PC_CLASS_SORCERER}).
* Вы получаете 2 [очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), которые можете тратить на [Метамагию](FEATURE:${FEATURE_METAMAGIC}) (эти очки добавляются к уже имеющимся у Вас из других источников [очкам чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), но их можно использовать только на [Метамагию](FEATURE:${FEATURE_METAMAGIC})). Вы восстанавливаете все потраченные [очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) в конце длинного отдыха.`,
    source: {
      id: SOURCE_TCoE,
      page: 120,
    },
  },
  {
    id: FEAT_METAMAGIC_ADEPT_2020_07_13,
    releasedAs: FEAT_METAMAGIC_ADEPT,
    name: `Адепт метамагии`,
    nameEn: `Metamagic Adept`,
    requirement: [
      {text: `Умение «Использование заклинаний»`},
      {featureId: FEATURE_PACT_MAGIC},
    ],
    description: `Вы научились изменять природу своих заклинаний одной лишь силой воли. Это даёт Вам следующие преимущества:

* Вы осваиваете два варианта [Метамагии](FEATURE:${FEATURE_METAMAGIC}) из доступных классу [чародея](PC_CLASS:${PC_CLASS_SORCERER}). Вы можете применить только один вариант Метамагии на сотворяемое заклинание, если только выбраный вариант не разрешает иного. Когда Вы получаете уровень, Вы можете заменить полученный этой чертой вариант [Метамагии](FEATURE:${FEATURE_METAMAGIC}) на другой вариант [Метамагии](FEATURE:${FEATURE_METAMAGIC}) [чародея](PC_CLASS:${PC_CLASS_SORCERER}).
* Вы получаете 2 [очка чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), которые можете тратить на [Метамагию](FEATURE:${FEATURE_METAMAGIC}) (эти очки добавляются к уже имеющимся у Вас из других источников [очкам чародейства](FEATURE:${FEATURE_SORCERY_POINTS}), но их можно использовать только на [Метамагию](FEATURE:${FEATURE_METAMAGIC})). Вы восстанавливаете все потраченные [очки чародейства](FEATURE:${FEATURE_SORCERY_POINTS}) в конце длинного отдыха.`,
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 2,
    },
  },
  {
    id: FEAT_PIERCER,
    name: `Пронзатель`,
    nameEn: `Piercer`,
    description: `Вы достигли пронзительной точности в бою, получая следующие преимущества:
    
* Увеличьте значение Силы или Ловкости на 1 при максимуме 20.
* Один раз в ход, когда Вы попадаете по существу атакой, наносящей колющий урон, Вы можете перебросить одну кость урона и должны использовать новый результат.
* Когда Вы попадаете по существу критическим ударом, наносящим колющий урон, Вы можете бросить ещё одну дополнительную кость урона, когда бросаете дополнительные кости колющего урона от критического удара.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: [
      {
        id: SOURCE_TCoE,
        page: 121,
      },
      {
        id: SOURCE_UA_2020_07_13_FEATS,
        page: 2,
      },
    ],
  },
  {
    id: FEAT_POISONER,
    name: `Отравитель`,
    nameEn: `Poisoner`,
    description: `Вы умеете готовить и использовать смертоносные яды, получая следующие преимущества:

* Когда Вы совершаете бросок урона, Вы игнорируете сопротивление яду.
* Вы можете покрывать оружие ядом бонусным действием, а не действием.
* Вы получаете владение [набором отравителя](GEAR:${GEAR_POISONERS_KIT}), если ещё не владели им. Проработав один час с [набором отравителя](GEAR:${GEAR_POISONERS_KIT}) и потратив материалы на 50 золотых, Вы можете создать количество порций мощного оружейного яда, равное Вашему бонусу мастерства. Нанесённый на оружие (или боеприпас) яд сохраняет свои свойства 1 минуту или пока этим оружием (или боеприпасом) не попадут по цели. Когда покрытое этим ядом оружие (или боеприпас) наносит урон существу, оно должно пройти испытание Телосложения СЛ 14 или получить 2к8 урона ядом и стать отравленным до конца Вашего следующего хода.`,
    instrumentList: INSTRUMENT_POISONERS_KIT,
    source: {
      id: SOURCE_TCoE,
      page: 121,
    },
  },
  {
    id: FEAT_POISONER_2020_07_13,
    releasedAs: FEAT_POISONER,
    name: `Отравитель`,
    nameEn: `Poisoner`,
    description: `Вы умеете готовить и использовать смертоносные яды, получая следующие преимущества:

* Когда Вы совершаете бросок урона, Вы игнорируете сопротивление яду.
* Вы можете покрывать оружие ядом бонусным действием, а не действием.
* Вы получаете владение [набором отравителя](GEAR:${GEAR_POISONERS_KIT}), если ещё не владели им. Проработав один час с [набором отравителя](GEAR:${GEAR_POISONERS_KIT}) и потратив материалы на 50 золотых, Вы можете создать количество порций мощного оружейного яда, равное Вашему бонусу мастерства. Нанесённый на оружие яд сохраняет свои свойства 1 минуту или пока этим оружием не попадут по цели. Когда покрытое этим ядом оружие наносит урон существу, оно должно пройти испытание Телосложения СЛ 14 или получить 2к8 урона ядом и стать отравленным до конца Вашего следующего хода.`,
    instrumentList: INSTRUMENT_POISONERS_KIT,
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 2,
    },
  },
  {
    id: FEAT_PRACTICED_EXPERT_2020_07_13,
    name: `Опытный эксперт`,
    nameEn: `Practiced Expert`,
    description: `Вы отточили своё владение определённым навыком или инструментом, получая следующие преимущества:

* Увеличьте значение любой характеристики на 1, при максимуме 20.
* Вы получаете владение одним навыком или инструментом по Вашему выбору.
* Выберите один из навыков или инструментов, которыми Вы владеете. Ваш бонус мастерства для него удваивается.`,
    paramIncrease: [
      PARAM_ANY,
    ],
    skillList: SKILL_ANY,
    instrumentList: INSTRUMENT_ANY,
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_SHADOW_TOUCHED,
    name: `Отмеченный тенью`,
    nameEn: `Shadow Touched`,
    description: `Вы поняли как привязать тени собственных страхов к Царству Теней, получая следующие преимущества:

* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы изучаете заклинание [Невидимость](SPELL:${SPELL_INVISIBILITY}) и одно заклинание 1 круга по Вашему выбору. Выбранное заклинание 1 круга должно принадлежать школам иллюзии или некромантии. Вы можете сотворять эти заклинания без траты ячейки заклинания. Сотворив так одно из этих заклинаний, Вы не можете сотворять его снова, пока не закончите длинный отдых. Также Вы можете сотворять эти заклинания, тратя свои ячейки заклинаний. Базовая характеристика для этих заклинаний это характеристика, увеличенная этой чертой.`,
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_TCoE,
      page: 121,
    },
  },
  {
    id: FEAT_SHADOW_TOUCHED_2020_07_13,
    releasedAs: FEAT_SHADOW_TOUCHED,
    name: `Отмеченный тенью`,
    nameEn: `Shadow Touched`,
    description: `Вы поняли как привязать тени собственных страхов к Царству Теней, получая следующие преимущества:

* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Вы изучаете заклинание [Тьма](SPELL:${SPELL_DARKNESS}) и одно заклинание 1 круга по Вашему выбору. Выбранное заклинание 1 круга должно принадлежать школам иллюзии или некромантии. Вы можете сотворять эти заклинания без траты ячейки заклинания. Сотворив так одно из этих заклинаний, Вы не можете сотворять его снова, пока не закончите длинный отдых. Также Вы можете сотворять эти заклинания, тратя свои ячейки заклинаний. Базовая характеристика для этих заклинаний это характеристика, увеличенная этой чертой.`,
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_SHIELD_TRAINING_2020_07_13,
    name: `Щитовая тренировка`,
    nameEn: `Shield Training`,
    description: `Вы научились эффективно использовать [щиты](GEAR:${GEAR_SHIELD}), получая следующие преимущества:

* Увеличьте значение Силы, Ловкости или Телосложения на 1 при максимуме 20.
* Вы получаете владение [щитами](GEAR:${GEAR_SHIELD}).
* В бою Вы можете надеть или снять [щит](GEAR:${GEAR_SHIELD}) взаимодействием на своём ходу.
* Если Вы владеете умением «Использование заклинаний» или «Магия договора», то можете использовать [щит](GEAR:${GEAR_SHIELD}) как заклинательную фокусировку.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
      PARAM_CON,
    ],
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_SLASHER,
    name: `Рубака`,
    nameEn: `Slasher `,
    description: `Вы научились рубить именно там, где нужно, получая следующие преимущества:

* Увеличьте значение Силы или Ловкости на 1 при максимуме 20.
* Один раз в ход, когда Вы попадаете по существу атакой, наносящей рубящий урон, Вы можете уменьшить скорость цели на 10 футов до начала Вашего следующего хода.
* Когда Вы попадаете по существу критическим ударом, наносящим рубящий урон, Вы тяжело раните его. До начала Вашего следующего хода цель совершает все атаки с помехами.`,
    paramIncrease: [
      PARAM_STR,
      PARAM_DEX,
    ],
    source: [
      {
        id: SOURCE_TCoE,
        page: 122,
      },
      {
        id: SOURCE_UA_2020_07_13_FEATS,
        page: 3,
      },
    ],
  },
  {
    id: FEAT_TANDEM_TACTICIAN_2020_07_13,
    name: `Парный тактик`,
    nameEn: `Tandem Tactician`,
    description: `Ваше присутствие в драке подбадривает Ваших товарищей. Вы получаете следующие преимущества:

* Вы можете совершать действие Помощь бонусным действием.
* Когда Вы используете действие Помощь, помогая союзнику атаковать существо, увеличьте дальность действия Помощь на 10 футов. Кроме того, когда Вы используете Помощь таким образом, Вы можете помочь сразу двум союзникам в атаке выбранного существа.`,
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_TRACKER_2020_07_13,
    name: `Ловец`,
    nameEn: `Tracker `,
    description: `Ваше провели много времени, охотясь на существ и оттачивая навыки выслеживания, получая следующие преимущества:

* Увеличьте значение Мудрости на 1 при максимуме 20.
* Вы изучаете заклинание [Метка охотника](SPELL:${SPELL_HUNTERS_MARK}). Вы можете сотворять его без траты ячейки заклинания и должны закончить длинный отдых, прежде чем сможете снова сотворить его этой чертой. Также Вы можете сотворить эти заклинания, тратя свои ячейки заклинаний. Базовая характеристика для этих заклинаний — Мудрость.
* Вы получаете преимущество к проверкам Мудрости (Выживания) для выслеживания существ.`,
    paramIncrease: [
      PARAM_WIT,
    ],
    source: {
      id: SOURCE_UA_2020_07_13_FEATS,
      page: 3,
    },
  },
  {
    id: FEAT_GIFT_OF_THE_CHROMATIC_DRAGON_2021_04_14,
    name: `Дар цветного дракона`,
    nameEn: `Gift of the Chromatic Dragon`,
    description: `Вы проявляете некоторые силы цветных драконов, что даёт Вам следующие преимущества:

* Вы можете бонусным действием коснуться простого или воинского оружия и наполнить его одним из следующих видов урона: кислота, холод, огонь, электричество иди яд. В течение следующей минуты это оружие будет наносить 1к4 урона выбранного типа при попадании. Использовав эту способность, Вы не можете использовать её снова, пока не завершите длинный отдых.
* Когда Вы получаете урон кислотой, холодом, огнём, электричеством иди ядом, Вы можете реакцией получить сопротивление к этой порции урона. Вы можете использовать эту реакцию число раз, равное Вашему бонусу мастерства. Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
      page: 4,
    },
  },
  {
    id: FEAT_GIFT_OF_THE_METALLIC_DRAGON_2021_04_14,
    name: `Дар металлического дракона`,
    nameEn: `Gift of the Metallic Dragon`,
    description: `Вы проявляете некоторые силы металлических драконов, что даёт Вам следующие преимущества:

* Вы изучаете заклинание [Лечение ран](SPELL:${SPELL_CURE_WOUNDS}). Вы можете сотворять его без траты ячейки заклинания и должны закончить длинный отдых, прежде чем сможете снова сотворить его этой чертой. Также Вы можете сотворить это заклинание, тратя свои ячейки заклинаний. Заклинательная характеристика для этого заклинания — Интеллект, Мудрость или Харизма на Ваш выбор (Вы выбираете её, когда получаете эту черту).
* Вы можете являть защитные крылья, которые защитят Вас или других от атак. Когда по Вам или другому видимому Вами в пределах 5 футов существу попадают атакой, Вы можете реакцией на мгновение явить из спины призрачные крылья. Бросьте 1к4 и добавьте результат к КД цели, что потенциально может привести к промаху атаки. Вы можете использовать эту реакцию число раз, равное Вашему бонусу мастерства. Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.`,
    source: {
      id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
      page: 4,
    },
  },
  {
    id: FEAT_GIFT_OF_THE_GEM_DRAGON_2021_04_14,
    name: `Дар самоцветного дракона`,
    nameEn: `Gift of the Gem Dragon`,
    description: `Вы проявляете некоторые силы самоцветных драконов, что даёт Вам следующие преимущества:

* Увеличьте значение Интеллекта, Мудрости или Харизмы на 1 при максимуме 20.
* Когда Вы получаете урон от существа, находящегося в пределах 10 футов, Вы можете реакцией испустить телекинетическую энергию. Нанёсшее урон существо должно пройти испытание Силы (СЛ 8 + Ваш бонус мастерства + модификатор характеристики, увеличенной этой чертой) или получить 2к8 урона силовым полем и оттолкнуться на 10 футов от Вас.Вы можете использовать эту реакцию число раз, равное Вашему бонусу мастерства. Вы восстанавливаете все израсходованные использования, когда заканчиваете длинный отдых.`,
    paramIncrease: [
      PARAM_INT,
      PARAM_WIT,
      PARAM_CHA,
    ],
    source: {
      id: SOURCE_UA_2021_04_14_DRACONIC_OPTIONS,
      page: 5,
    },
  },
  {
    id: FEAT_SCION_OF_THE_OUTER_PLANES_2022_07_18,
    name: `Отпрыск Внешних планов`,
    nameEn: `Scion of the Outer Planes`,
    description: `На Вас оказала сильное влияние искаженная реальность Внешних Планов, Вы чувствуете родство с ними и умеете ориентироваться в них и искать переходы между ними.

Родились ли Вы на Внешнем плане или же Вас пропитала его сущность, эта связь с планом наполняет его Вас энергиями. Выберите тип плана, указанный ниже в таблице «Влияние планов». Ваш выбор даёт Вам сопротивление к типу урона и возможность сотворять заговор, как указано в таблице. Вы можете сотворять этот заговор без материальных компонентов, и ваша заклинательная способность для него — Интеллект, Мудрость или Харизма (Вы выбираете её, когда получаете эту черту).

# Влияние планов
| План | Сопротивление урону | Заговор |
|-|-|-|
| Астральный план      | Психический   | [Сообщение](SPELL:${SPELL_MESSAGE}) |
| Внешние земли        | Психический   | [Волшебная рука](SPELL:${SPELL_MAGE_HAND}) |
| Внешний план добра   | Излучение     | [Священное пламя](SPELL:${SPELL_SACRED_FLAME}) |
| Внешний план зла     | Некротический | [Ледяное прикосновение](SPELL:${SPELL_CHILL_TOUCH}) |
| Внешний план порядка | Излучение     | [Указание](SPELL:${SPELL_GUIDANCE}) |
| Внешний план хаоса   | Некротический | [Малая иллюзия](SPELL:${SPELL_MINOR_ILLUSION}) |
`,
    paramIncrease: PARAM_ANY,
    source: {
      id: SOURCE_UA_2022_07_18_WotM,
      page: 9,
    },
  },
  {
    id: FEAT_AGENT_OF_ORDER_2022_07_18,
    name: `Агент порядка`,
    nameEn: `Agent of Order`,
    description: `Вы можете направлять силы космического порядка, определяющие взаимосвязи в мультивселенной. Ваши действия зависят от вас, но эти силы дают вам следующие преимущества:

# Увеличение характеристик

Увеличьте значение любой характеристики на 1, при максимуме 20.

# Удар стазиса

Один раз в ход, когда Вы наносите урон видимому Вами в пределах 60 футов существу, Вы можете нанести ему дополнительно 1к8 урона силовым полем и оно должно пройти испытание Мудрости (СЛ 8 + Ваш бонус мастерства + Ваш модификатор характеристики, которую Вы увеличили этой чертой) или станет Обездвиженной призрачными путами до начала Вашего следующего хода. Эти путы проявляются как цепи,
механизмы, каменная оболочка, либо иное воплощение стазиса. Вы можете использовать эту способность число раз, равное Вашему бонусу мастерства и восстанавливаете все использования, оканчивая длинный отдых.`,
    paramIncrease: PARAM_ANY,
    source: {
      id: SOURCE_UA_2022_07_18_WotM,
      page: 6,
    },
  },
  {
    id: FEAT_BALEFUL_SCION_2022_07_18,
    name: `Злобный отпрыск`,
    nameEn: `Baleful Scion`,
    description: `Вы можете направлять силы космического зла, которые причиняют боль, но укрепляют Вас. Несмотря на эту пагубную связь, Вы отлично контролируете свои действия. Вы получаете следующие преимущества

# Увеличение характеристик

Увеличьте значение любой характеристики на 1, при максимуме 20.

# Высасывающая жизнь хватка

Один раз в ход, когда Вы попадаете по существу атакой рукопашным оружием, Вы можете нанести ему дополнительно некротический урон в размере 1к6 + Ваш бонус мастерства и Вы восстанавливаете количество хитов, равное этому некротическому урону. Вы можете использовать эту способность число раз, равное Вашему бонусу мастерства и восстанавливаете все использования, оканчивая длинный отдых.`,
    paramIncrease: PARAM_ANY,
    requirement: {
      feat: {
        id: FEAT_SCION_OF_THE_OUTER_PLANES_2022_07_18,
        comment: 'Внешний план зла',
      },
      lvl: 4,
    },
    source: {
      id: SOURCE_UA_2022_07_18_WotM,
      page: 6,
    },
  },
  {
    id: FEAT_CARTOMANCER_2022_07_18,
    name: `Картомант`,
    nameEn: `Cartomancer`,
    description: `Вы научились направлять свою магию через колоду игральных карт, что даёт Вам следующие преимущества:

# Карточный фокус

Вы можете использовать колоду карт в качестве заклинательной фокусировки. Когда Вы используете её для фокусировки заклинания, наносящего урон, бросьте к4. Вы получаете бонус к одной кости урона заклинания, равный выпавшему числу. Этот бонусный урон применяется к одному видимому Вами существу, которому это заклинание наносит урон. Вы можете использовать эту способность число раз, равное Вашему бонусу мастерства и восстанавливаете все использования, оканчивая длинный отдых.

# Трюки с картами

Вы изучаете [Фокусы](SPELL:${SPELL_PRESTIDIGITATION}) и можете создавать ими иллюзии, дублирующие эффекты сценической магии. Используя [Фокусы](SPELL:${SPELL_PRESTIDIGITATION}) таким образом, Вы можете замаскировать вербальные и соматические компоненты заклинания под видом как обыденной беседы и обращения с картами.

# Туз в рукаве

Заканчивая длинный отдых, Вы можете выбрать одно известное Вам заклинание и вложить его в карту; время сотворения выбранного заклинания должно быть 1 действие, а его уровень должен быть меньше или равен Вашему бонусу мастерства. Пока карта наполнена заклинанием, вы можете бонусным действием достать эту карту и разыграть вложенное в неё заклинание. После этого карта сразу теряет свою магию.`,
    requirement: {
      pcClassIdList: [
        PC_CLASS_SORCERER,
        PC_CLASS_WARLOCK,
        PC_CLASS_WIZARD,
      ],
      lvl: 4,
    },
    source: {
      id: SOURCE_UA_2022_07_18_WotM,
      page: 6,
    },
  },
]
